import React, { useState, useEffect } from 'react';
import appUrl from "../../../../constants/appUrl";
import FaqByProduct from './FaqByProduct';
import './FaqChannel.css';

const FaqChannel = ({ channelID }) => {
    const [productList, setProductList] = useState([]);
    const [activeProductId, setActiveProductId] = useState(null);

    const getAllProduct = async () => {
        const token = localStorage.getItem('token');

        try {
            const response = await fetch(`${appUrl.baseUrl}api/product/GetAllProducts`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setProductList(data);
            if (data.length > 0) {
                setActiveProductId(data[0].productId); // Set the first product as active by default
            }
        } catch (error) {
            console.error('Error fetching products:', error);
            // Optionally, set an error state here
        }
    };

    useEffect(() => {
        getAllProduct();
    }, []);

    const handleButtonClick = (productId) => {
        setActiveProductId(productId);
    };

    return (
        <div className='grid ' >
            <div className='md:col-12'>
                <div className='table-customs'>
                    <div className='button-container'>
                        {productList.map((product) => (
                            <button
                                key={product.productId}
                                className={`tab-button ${activeProductId === product.productId ? 'active' : ''}`}
                                onClick={() => handleButtonClick(product.productId)}
                            >
                                {product.productName}
                            </button>
                        ))}
                    </div>
                    <div className='content-container'>
                        {productList.map((product) => (
                            activeProductId === product.productId && (
                                <FaqByProduct
                                    key={product.productId}
                                    productID={product.productId}
                                    channelID={channelID}
                                />
                            )
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FaqChannel;
