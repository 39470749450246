import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import appUrl from "../../../../constants/appUrl";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { InputSwitch } from "primereact/inputswitch";
import AddEditAdminDisclaimer from '../compoenets/AddEditAdminDisclaimer';
import Loader from '../../../components/laoder';

const AdminDisclaimer = ({ searchQuery }) => {
    const [rowselect, setRowselect] = useState(null);
    const [dialog, setDialog] = useState(false);
    const [editable, setEditable] = useState(false);
    const [res, setRes] = useState(null);
    const [disclaimerList, setDisclaimerList] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(false);
    const GetAllDisclaimer = async () => {
        const token = localStorage.getItem('token')
        try {
            setLoading(true);
            const response = await fetch(`${appUrl.baseUrl}api/Disclaimer/GetAllDisclaimers`,
                {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            const data = await response.json();
            setDisclaimerList(data);
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        GetAllDisclaimer();
    }, [res]);

    useEffect(() => {
        if (searchQuery) {
            setFilteredData(
                disclaimerList.filter((item) =>
                    item.disclaimerDescription?.toLowerCase().includes(searchQuery?.toLowerCase())
                )
            );
        } else {
            setFilteredData(disclaimerList);
        }
    }, [searchQuery, disclaimerList]);

    const toggleStatus = async (rowData) => {
        const updatedRow = { ...rowData, active: !rowData.active };
        try {
            const response = await axios.patch(`${appUrl.baseUrl}api/Disclaimer/ActiveDeactiveDisclaimer/${rowData?.disclaimerID}`, updatedRow);
            setRes(response);
            toast.success("Status Updated Successfully");
        } catch (error) {
            console.error(error);
            toast.error("Error Updating Status");
        }
    };

    const statusBodyTemplate = (rowData) => (
        <InputSwitch checked={rowData.active} onChange={() => toggleStatus(rowData)} />
    );

    const actionTemplate = (rowData) => (
        <div>
            <Button icon="pi pi-pencil" className="custom-btn-edit" onClick={() => editAction(rowData)} />
            <Button icon="pi pi-trash" className="custom-btn-delete" onClick={() => { confirm(rowData) }} />
        </div>
    );

    const accept = async (rowData) => {
        try {
            const response = await axios.delete(`${appUrl.baseUrl}api/Disclaimer/DeleteDisclaimerByID/${rowData?.disclaimerID}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            });
            setRes(response.data);
            toast.success("Deleted Successfully");
        } catch (error) {
            toast.error("Failed to delete. Please try again.");
            console.error("Error deleting disclaimer:", error);
        }
    };
    const reject = () => { };

    const confirm = (rowData) => {
        confirmDialog({
            header: (
                <div className="custom-header mb-3 mt-3">
                    <i className="pi pi-trash bg-reddd"></i>
                </div>
            ),
            message: (
                <strong>Are you sure you want to delete this disclaimer?</strong>
            ),
            accept: () => accept(rowData),
            reject: () => reject(),
            acceptClassName: 'custom-btn-red',
            rejectClassName: 'custom-white-red',
            className: 'center-buttons no-close-button',
        });
    };

    const editAction = (rowData) => {
        setEditable(true);
        setRowselect(rowData);
        setDialog(true);
    };

    const onHide = () => {
        setDialog(false);
    };

    const isAdmin = localStorage.getItem('admin');

    return (
        <>
        <ConfirmDialog />
            <ToastContainer />
            <Dialog visible={dialog} onHide={onHide}
                header={editable ? "Edit Disclaimer" : "Add Disclaimer"} style={{ width: "60vw" }}>
                <AddEditAdminDisclaimer editable={editable} onHide={onHide} dialog={dialog} setRes={setRes} rowData={rowselect} setDialog={setDialog} />
            </Dialog>

            <div className='grid'>
                <div className="xl:col-10 md:col-8 sm:col-8 col-4">
                    <h5 style={{ fontWeight: "bold" }}> Disclaimer</h5>
                </div>
               
                {isAdmin === 'true' || isAdmin === true ? (
                    <div className="xl:col-2 md:col-4 sm:col-4 col-8">
                        <Button className="custom-btn w80" label="+&nbsp; Add New" onClick={() => { setEditable(false); setDialog(true) }} />
                    </div>
                ) : null}
                <div className='md:col-12 overflowX'>
                {loading && <Loader />}
                    <div className='table-custom'>
                        <DataTable
                            value={filteredData}
                            scrollable
                            scrollHeight="600px"
                            rows={9}
                            paginator
                            className=''
                        >
                            <Column header="Disclaimer" className='pointer' body={(rowData) => (
                                <span title={rowData.disclaimerDescription}>
                                    {rowData.disclaimerDescription.length > 15 ? `${rowData.disclaimerDescription.slice(0, 15)}...` : rowData.disclaimerDescription}
                                </span>
                            )} />

                            <Column field='createdDate' header="Created Date / Time"
                                body={(rowData) => new Date(rowData.createdDate).toLocaleString('en-GB', {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    hour12: true,
                                })} />
                            <Column
                                field="updatedDate"
                                header="Updated Date/Time"
                                body={(rowData) => {
                                    if (!rowData.updatedDate) {
                                        return '-';
                                    }
                                    return new Date(rowData.updatedDate).toLocaleString('en-GB', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        hour12: true,
                                    });
                                }}
                            />
                            <Column header="Status" body={statusBodyTemplate} />
                            <Column header="Action" body={actionTemplate} style={{ minWidth: '10rem' }}/>
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdminDisclaimer;

