import React, { useState, useEffect } from 'react';
import appUrl from "../../../../constants/appUrl";
import { Dialog } from 'primereact/dialog';
import AddEditSocialMedia from '../../SocialMedia/components/AddEditSocialMedia';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ViewAttachment from '../../ProcessInformation/components/ViewAttachment';
import { InputText } from 'primereact/inputtext';

import { FilterMatchMode } from "primereact/api";
const UserSocialMedia = () => {
  const [socialList, setSocialList] = useState([]);
  const [dialog, setDialog] = useState(false);
  const [channelList, setChannelList] = useState([]);
  const [res, setRes] = useState([]);
  const [viewAttachmentDialog, setViewAttachmentDialog] = useState(false);
  const [selectedAttachment, setSelectedAttachment] = useState(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  const isAdmin = localStorage.getItem('admin');

  const getSocial = async () => {
    try {
      const response = await fetch(`${appUrl.baseUrl}api/Post/GetAllPost`);
      const data = await response.json();
      setSocialList(data);
    } catch (error) {
      error('Error fetching products:', error);
    }
  };

  const getAllChannel = async () => {
    try {
      const response = await fetch(`${appUrl.baseUrl}api/Channel/GetAllChannels`);
      const data = await response.json();
      setChannelList(data);
    } catch (error) {
      error(error);
    }
  };

  const onHide = () => {
    setDialog(false);
  };
  const onHideViewAttachment = () => {
    setViewAttachmentDialog(false);
  };
  const actionEye = (rowData) => {
    return (
      <div>
        <Button icon="pi pi-eye" className="custom-btn-edit"
          onClick={() => {
            setSelectedAttachment(rowData.postAttachments);
            setViewAttachmentDialog(true);
          }} />
      </div>
    );
  }
  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
};
  useEffect(() => {
    getSocial();
    getAllChannel();
  }, [res]);

  return (
    <>
      <Dialog visible={viewAttachmentDialog} onHide={onHideViewAttachment} header="View Attachment" style={{ width: '50vw' }}>
        {selectedAttachment && <ViewAttachment attachment={selectedAttachment} />}
      </Dialog>
      <Dialog visible={dialog} onHide={onHide} header={"Add New"} style={{ width: "60vw" }}>
        <AddEditSocialMedia dialog={dialog} setRes={setRes} setDialog={setDialog} />
      </Dialog>



      <div className='grid'>
        <div className='md:col-8'> <h5 style={{ fontWeight: "bold" }}>Tutorials and Videos</h5></div>
        <div className='md:col-2'>

          {/* <InputText className="mbl_view w100" value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" /> */}
        </div>
        {isAdmin === 'true' || isAdmin === true ? (
          <div className='md:col-2'>
            <Button className="custom-btn w80" label="+ &nbsp; Add New" onClick={() => { setDialog(true) }} />
          </div>
        ) :
          null
        }

      </div>

      <div className='grid'>

        <div className='md:col-12'>
          <div className='table-custom' >
            <DataTable
              value={socialList}
              scrollable
              globalFilterFields={["postTitle", "Description",]}
              filters={filters}

              scrollHeight="600px"
              rows={9}
              paginator
            >
              <Column field='postTitle' header="Title" />
              <Column field='channelName' header="Channel Name" />
              {/* <Column field='postDescription' header="Description" /> */}
              <Column header="Description" className='pointer'
                body={(rowData) => (
                  <span
                  title={stripHtmlTags(rowData.postDescription)}
                    dangerouslySetInnerHTML={{ __html: rowData.postDescription.slice(0, 20) + '...' }}
                  />
                )}
              />
              <Column field='createdDate' header="Created Date / Time"
                body={(rowData) => new Date(rowData.createdDate).toLocaleString('en-GB', {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: true
                })} />
              <Column header="Attachments" body={actionEye} />

            </DataTable>

          </div>
        </div>
      </div>

    </>
  );
};

export default UserSocialMedia;
