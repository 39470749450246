import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import ViewAttachment from '../../ComplainHandling/components/ViewAttachment';
import CustomDescription from '../../../components/CustomDescription';
import CustomVideo from '../../../components/CustomVideo';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import appUrl from "../../../../constants/appUrl";
const TableVideos = ({ callCenter, searchQuery }) => {

    const [viewAttachmentDialog, setViewAttachmentDialog] = useState(false);
    const [selectedAttachment, setSelectedAttachment] = useState(null);
    const [dialog, setDialog] = useState();
    const [tableData, setTableData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [descriptionDialog, setDescriptionDialog] = useState(false);
    const [descriptionContent, setDescriptionContent] = useState('');
    const [videoAttachmentDialog, setVideoAttachmentDialog] = useState(false);
    const [selectedVideoAttachment, setSelectedVideoAttachment] = useState(null);

    useEffect(() => {
        setTableData(callCenter)
    }, [callCenter])

    const onHideViewAttachment = () => {
        setViewAttachmentDialog(false);
    };
    const onHide = () => {
        setDialog(false)
    }
    const actionDocument = (rowData) => {
        const documentsToDownload = rowData.postAttachments.filter(attachment =>
            attachment.attachmentPath.endsWith('.docx') ||
            attachment.attachmentPath.endsWith('.pdf') ||
            attachment.attachmentPath.endsWith('.xlsx')
        );

        const documentPaths = documentsToDownload.map(document => document.attachmentPath);
         const hasImage = rowData?.postAttachments?.some(attachment => attachment?.attachmentPath?.endsWith('.png') || attachment?.attachmentPath?.endsWith('.jpg') || attachment?.attachmentPath?.endsWith('.jpeg'));
        const hasVideo = rowData.postAttachments.some(attachment => attachment.attachmentPath.endsWith('.mp4') || attachment.attachmentPath.endsWith('.mp5') || attachment.attachmentPath.endsWith('.webm') || attachment.attachmentPath.endsWith('.mov') || attachment.attachmentPath.endsWith('.3gp') || attachment.attachmentPath.endsWith('.3g2') || attachment.attachmentPath.endsWith('.mpeg'));

        return (
            <div>
                {documentsToDownload.length > 0 && (
                    <Button
                        icon="pi pi-download"
                        className="custom-btn-edit"
                        onClick={() => downloadDocuments(documentPaths)}
                    />
                )}
                {hasImage && (
                    <Button icon="pi pi-eye" className="custom-btn-edit" onClick={() => {
                        setSelectedAttachment(rowData.postAttachments.filter(attachment => attachment.attachmentPath.endsWith('.png') || attachment.attachmentPath.endsWith('.jpg') || attachment.attachmentPath.endsWith('.jpeg')));
                        setViewAttachmentDialog(true);
                    }} />
                )}
                {hasVideo && (
                    <Button icon="pi pi-video" className="custom-btn-edit" onClick={() => {
                        setSelectedVideoAttachment(rowData.postAttachments.filter(attachment => attachment.attachmentPath.endsWith('.mp4') || attachment.attachmentPath.endsWith('.mp5') || attachment.attachmentPath.endsWith('.webm')|| attachment.attachmentPath.endsWith('.mov')|| attachment.attachmentPath.endsWith('.3gp')|| attachment.attachmentPath.endsWith('.3g2')|| attachment.attachmentPath.endsWith('.mpeg')));
                        setVideoAttachmentDialog(true);
                    }} />
                )}
                {(documentsToDownload.length === 0 && !hasImage && !hasVideo) && (
                <Button
                    // icon="pi pi-question"
                    label='-'
                    className="custom-btn-edit"
                    tooltip="No attachment available"
                />
            )}
            </div>
        );
    }

    const downloadDocuments = async (documentPaths) => {
        try {
            for (let i = 0; i < documentPaths.length; i++) {
                const documentPath = documentPaths[i];
                const response = await fetch(`${appUrl.baseUrl}${documentPath}`);
                const blob = await response.blob();
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', '');
                link.setAttribute('target', '_blank');
                link.click();
                URL.revokeObjectURL(url);
                await new Promise(resolve => setTimeout(resolve, 1000)); // Delay each download by 1 second
            }
        } catch (error) {
            console.error("Error downloading documents:", error);
            toast.error("Failed to download documents");
        }
    };

    const showDescription = (description) => {
        setDescriptionContent(description);
        setDescriptionDialog(true);
    };
    const onHideDescriptionDialog = () => {
        setDescriptionDialog(false);
    };

    const onHideVideoAttachment = () => {
        setVideoAttachmentDialog(false);
    };
    useEffect(() => {
        if (searchQuery) {
            setFilteredData(
                tableData?.filter((item) =>
                    item.postTitle?.toLowerCase().includes(searchQuery?.toLowerCase())||
                item.productName?.toLowerCase().includes(searchQuery?.toLowerCase())
                )
            );
        } else {
            setFilteredData(tableData);
        }
    }, [searchQuery, tableData]);
    return (
        <>
 <ToastContainer />
            <Dialog visible={viewAttachmentDialog} onHide={onHideViewAttachment} header="View Attachment" style={{ width: '50vw' }}>
                {selectedAttachment && <ViewAttachment attachment={selectedAttachment} />}
            </Dialog>
            <Dialog visible={descriptionDialog} onHide={onHideDescriptionDialog} header="Description" style={{ width: '50vw' }}>
                <CustomDescription description={descriptionContent} />
            </Dialog>
            <Dialog visible={videoAttachmentDialog} onHide={onHideVideoAttachment} header="View Video Attachment" style={{ width: '50vw' }}>
                {selectedVideoAttachment && <CustomVideo videoAttachments={selectedVideoAttachment} />}
            </Dialog>

            <div className=''>


                <div className='grid'>
                    <div className='md:col-12 overflowX' >
                        <div className='table-custom'>
                            <DataTable
                                value={filteredData}
                                scrollable
                                scrollHeight="600px"
                                rows={9}
                                paginator
                            >
                                <Column field='productName' header="Product" />
                                <Column field='channelName' header="Channel " />
                                <Column field='postTitle' header="Title" />

                                <Column
                                    header="Description"
                                    body={(rowData) => (
                                        <a
                                            href="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                showDescription(rowData.postDescription);
                                            }}
                                            style={{ cursor: 'pointer', color: '#00b0ab', textDecoration: 'underline' }}
                                        >
                                            View Description
                                        </a>
                                    )}
                                />


                                <Column header="Attachment" body={actionDocument} />
                            </DataTable>
                        </div>
                    </div>
                </div>

            </div>

        </>
    );
};

export default TableVideos;
