import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useFormik } from 'formik';
import { Dropdown } from 'primereact/dropdown';
import * as Yup from 'yup';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { MultiSelect } from 'primereact/multiselect';
import appUrl from "../../../../constants/appUrl";

const AddEditAdminOutage = ({ editable, setRes, rowData, setDialog, onHide }) => {
  const [channelList, setChannelList] = useState();
  const [productList, setProductList] = useState();
  const [statusList, setStatusList] = useState();
  const [apiRes, setApiRes] = useState();
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    // channelID: Yup.array().min(1, 'Required').of(Yup.string()).required('Required'),
    // reason: Yup.string().required('Required').max(150, 'Reason must be 150 characters'),
    status: Yup.string().required('Required'),
  });

  const formik = useFormik(
    {
      validationSchema: validationSchema,
      initialValues: {
        productID: '',
        // channelID: [],
        status: '',
        reason: '',
      },
      onSubmit: async (data) => {
        setLoading(true);
        if (!data.productID) {
          data.productID = 0;
        }
        const token = localStorage.getItem('token');
        if (!token) {
          toast.error("No token found. Please log in again.");
          return;
        }
        try {
          if (editable) {
            data.id = rowData.id;
            const response = await axios.patch(`${appUrl.baseUrl}api/OutageDegradation/UpdateOutageDegradation`, data, {
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
              }
            });
            setApiRes(response);
            toast.success("Update Successfully");
            setRes(response);
          } else {
            const response = await axios.post(`${appUrl.baseUrl}api/OutageDegradation/AddNewOutageDegradation`, data, {
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
              }
            });
            setApiRes(response);
            toast.success("Added Successfully");
            setRes(response);
          }
          setDialog(false);
        } catch (error) {
          toast.error("Something went wrong");
        } finally {
          setLoading(true);
          onHide();
        }
      },
    });

  useEffect(() => {
    if (rowData) {

      formik.setFieldValue('productID', rowData.productID);
      // formik.setFieldValue('channelID', rowData.channelId?.length > 0 ? rowData?.channelId : []);
      formik.setFieldValue('reason', rowData.reason);
      formik.setFieldValue('status', rowData.statusId);
    }
  }, [rowData]);

  const getAllChannel = async () => {
    const token = localStorage.getItem('token')
    try {
      const response = await fetch(`${appUrl.baseUrl}api/Channel/GetAllChannels`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      const data = await response.json();
      setChannelList(data);
    } catch (error) {
      error(error);
    }
  }

  const getStatus = async () => {
    const token = localStorage.getItem('token')
    try {
      const response = await fetch(`${appUrl.baseUrl}api/OutageDegradation/GetOutageStatus`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      const data = await response.json();
      setStatusList(data);
    } catch (error) {
      error(error);
    }
  }

  const getAllProduct = async () => {
    const token =localStorage.getItem('token')
    try {
      const response = await fetch(`${appUrl.baseUrl}api/Product/GetAllProducts`,{
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      const data = await response.json();
      setProductList(data);
    } catch (error) {
      error(error);
    }
  }

  useEffect(() => {
    getAllChannel();
    getAllProduct();
    getStatus();
  }, []);
  return (
    <>
      <ToastContainer />
      <form onSubmit={formik.handleSubmit}>
        <div className="grid">
          <div className="col-6 md:col-6 lg:col-6 xs:col-12">
            <div className="flex flex-column">
              <label className="mb-2" style={{ fontWeight: "bold" }}>Product Name </label>
              <Dropdown
                value={formik.values.productID}
                optionLabel="productName"
                name="productID"
                options={productList}
                disabled={editable}
                optionValue="productId"
                placeholder="Select"
                onChange={formik.handleChange}
              />
            </div>
          </div>
          {/* <div className="col-6 md:col-6 lg:col-6 xs:col-12">
            <div className="flex flex-column">
              <label className="mb-2" style={{ fontWeight: "bold" }}>Channel Name <span style={{ color: 'red' }}>*</span></label>
              <MultiSelect
                value={formik.values.channelID}
                optionValue="channelID"
                optionLabel="channelName"
                name="channelID"
                options={channelList}
                placeholder="Select"
                disabled={editable}
                onChange={formik.handleChange}
                filter
              />
              {formik.touched.channelID && formik.errors.channelID ? <div className='error'>{formik.errors.channelID}</div> : null}
            </div>
          </div> */}
          <div className="col-6 md:col-6 lg:col-6 xs:col-12">
            <div className="flex flex-column">
              <label className="mb-2" style={{ fontWeight: "bold" }}>Reason </label>
              <InputText
                name="reason"
                id="reason"
                placeholder="Enter Reason"
                value={formik.values.reason}
                onChange={formik.handleChange}
              />
              {/* {formik.touched.reason && formik.errors.reason ? <div className='error'>{formik.errors.reason}</div> : null} */}
            </div>
          </div>
          <div className="col-6 md:col-6 lg:col-6 xs:col-12">
            <div className="flex flex-column">
              <label className="mb-2" style={{ fontWeight: "bold" }}>Status <span style={{ color: 'red' }}>*</span></label>
              <Dropdown
                value={formik.values.status}
                optionLabel="status"
                name="status"
                options={statusList}
                optionValue="statusId"
                placeholder="Select"
                onChange={formik.handleChange}
              />
              {formik.touched.status && formik.errors.status ? <div className='error'>{formik.errors.status}</div> : null}
            </div>
          </div>
          <div className="col-12 md:col-8"></div>
          <div className="col-12 md:col-2" >
            <div className="flex justify-content-center">
              <Button className="custom-white w100 " label='Cancel' type='button' onClick={onHide} />
            </div>
          </div>
          <div className="col-12 md:col-2" >
            <div className="flex justify-content-center">
              <Button className="custom-btn w100" label={editable ? 'Update' : 'Add '} type='submit' disabled={loading} />
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default AddEditAdminOutage;
