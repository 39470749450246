import React, { useState, useEffect } from 'react'
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import appUrl from "../../../../constants/appUrl";
import { FilterMatchMode } from "primereact/api";
import { InputText } from 'primereact/inputtext';

const Useroutage = ({searchQuery}) => {
    const [zOutageList, setZOutageList] = useState([]);
    const [expandedRows, setExpandedRows] = useState(null);
    
    const [filteredData, setFilteredData] = useState([]);
    
    const GetAllAppJournies = async () => {
        try {
            const response = await fetch(`${appUrl.baseUrl}api/OutageDegradation/GetAllOutageDegradation`);
            const data = await response.json();
            setZOutageList(data);
        } catch (error) {
            error('Error fetching :', error);
        }
    };
    const allowExpansion = (rowData) => {
        return rowData.orders.length > 0;
    };

  

    useEffect(() => {
        if (searchQuery) {
            setFilteredData(
                zOutageList.filter((item) =>
                    item.productName?.toLowerCase().includes(searchQuery?.toLowerCase())
                )
            );
        } else {
            setFilteredData(zOutageList);
        }
    }, [searchQuery, zOutageList]);


    useEffect(() => {
        GetAllAppJournies();
    }, []);
    return (
        <div>


            <div className='grid'>
                <div className='md:col-10'>
                    <h5 style={{ fontWeight: "bold" }}>Outages / Degradations</h5>
                </div>
                <div className='md:col-2'>

                    
                </div>



                <div className='md:col-12' >
                    <div className='table-custom'>
                    <DataTable
                        value={filteredData}
                        scrollHeight="600px"
                        rows={9}
                        paginator
                       
                    >
                        {/* <Column header="Product ID" field='productID' /> */}
                        <Column header="Product " field='productName'  className='left-border' />
                        <Column header="Channel " field='channelName' />

                        <Column field="reason" header="Reason" />
                        <Column field='createdDate' header="Created Date / Time"  
                            body={(rowData) => new Date(rowData.createdDate).toLocaleString('en-GB', {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit',
                                hour12: true
                            })} />
                            <Column field="updatedDate" header="Updated Date/Time" className='right-border'
                            body={(rowData) => new Date(rowData.updatedDate).toLocaleString('en-GB', {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit',
                                hour12: true
                            })} />

                       
                           

                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Useroutage