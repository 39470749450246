import React, { useState, useEffect } from 'react'

import appUrl from "../../../../constants/appUrl";
import TableProcessInformation from './TableProcessInformation';
import TableFaq from './TableFaq';
const InAppFaqs = ({searchQuery}) => {
    const [faqList, setFaqList] = useState();

    const GetAllProcess = async () => {
        const token =localStorage.getItem('token')
        try {
            const response = await fetch(`${appUrl.baseUrl}api/FAQ/GetAllFAQ`,
                {
                    method:'GET',
                    headers:{
                        'Authorization':`Bearer ${token}`,
                        'Content-Type':'application/json'
                    }
                }
            );
            const data = await response.json();
            // setComplaintList(data)
            // setFaqList(data?.filter(item => item?.channelId?.filter(item2 => item2?.channelId == 2)))
            let result = data.filter(item => item.channelId && item.channelId.includes(2));
            setFaqList(result)
        }
        catch (error) {

        }
    }
    useEffect(() => {
        GetAllProcess();
    }, []);

    return (
        <TableFaq callCenter={faqList} searchQuery={searchQuery}/>
    )
}

export default InAppFaqs