import React, { useState, useEffect } from 'react';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Dialog } from 'primereact/dialog';
import axios from 'axios';
import appUrl from '../../../../constants/appUrl';
import AddEditProcessInformation from '../components/AddEditProcessInformation';
import ViewAttachment from '../components/ViewAttachment';

import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { FilterMatchMode } from "primereact/api";
import { InputText } from 'primereact/inputtext';
import CustomDescription from '../../../components/CustomDescription';
import CustomVideo from '../../../components/CustomVideo';
const ProcessInformation = ({ product, productId2, searchQuery }) => {
    console.log('productId2', productId2)
    console.log('productId', product)

    const [dialog, setDialog] = useState();
    const [editable, setEditable] = useState();
    const [rowselect, setRowselect] = useState(null)
    const [res, setRes] = useState();
    const [viewAttachmentDialog, setViewAttachmentDialog] = useState(false);
    const [selectedAttachment, setSelectedAttachment] = useState(null);
    const [productList, setProductList] = useState([]);

    const [filteredData, setFilteredData] = useState([]);
    const [descriptionDialog, setDescriptionDialog] = useState(false);
    const [descriptionContent, setDescriptionContent] = useState('');
    const [videoAttachmentDialog, setVideoAttachmentDialog] = useState(false);
    const [selectedVideoAttachment, setSelectedVideoAttachment] = useState(null);

    const GetProcessById = async (productId, productId2) => {
        const token =localStorage.getItem('token')
        try {

            const response = await fetch(`${appUrl.baseUrl}api/Process/GetProcessByProductId/${productId || productId2}`,
                {
                    method:'GET',
                    headers:{
                        'Authorization':`Bearer ${token}`,
                        'Content-Type':'application/json'
                    }
                }
            );

            const data = await response.json();
            setProductList(data);

        } catch (error) {
            error('Error fetching :', error);
        }
    };

    useEffect(() => {
        if ((product && product?.productId) || productId2) {
            GetProcessById(product?.productId, productId2);
        }
    }, [product, res, productId2]);

    const onHideVideoAttachment = () => {
        setVideoAttachmentDialog(false);
    };

    const actionDocument = (rowData) => {
        const documentsToDownload = rowData.processAttachments.filter(attachment =>
            attachment.attachmentPath.endsWith('.docx') ||
            attachment.attachmentPath.endsWith('.pdf') ||
            attachment.attachmentPath.endsWith('.xlsx')
        );

        const documentPaths = documentsToDownload.map(document => document.attachmentPath);
         const hasImage = rowData.processAttachments.some(attachment => attachment.attachmentPath.endsWith('.png') || attachment.attachmentPath.endsWith('.jpg') || attachment.attachmentPath.endsWith('.jpeg'));
        const hasVideo = rowData.processAttachments.some(attachment => attachment.attachmentPath.endsWith('.mp4') || attachment.attachmentPath.endsWith('.mp5') || attachment.attachmentPath.endsWith('.webm')|| attachment.attachmentPath.endsWith('.mov')|| attachment.attachmentPath.endsWith('.3gp')|| attachment.attachmentPath.endsWith('.3g2')|| attachment.attachmentPath.endsWith('.mpeg'));

        return (
            <div>
                 {documentsToDownload.length > 0 && (
                    <Button
                        icon="pi pi-download"
                        className="custom-btn-edit"
                        onClick={() => downloadDocuments(documentPaths)}
                    />
                )}
                {hasImage && (
                    <Button icon="pi pi-eye" className="custom-btn-edit" onClick={() => {
                        setSelectedAttachment(rowData.processAttachments.filter(attachment => attachment.attachmentPath.endsWith('.png') || attachment.attachmentPath.endsWith('.jpg') || attachment.attachmentPath.endsWith('.jpeg')));
                        setViewAttachmentDialog(true);
                    }} />
                )}
                {hasVideo && (
                    <Button icon="pi pi-video" className="custom-btn-edit" onClick={() => {
                        setSelectedVideoAttachment(rowData.processAttachments.filter(attachment => attachment.attachmentPath.endsWith('.mp4') || attachment.attachmentPath.endsWith('.mp5') || attachment.attachmentPath.endsWith('.webm')|| attachment.attachmentPath.endsWith('.mov')|| attachment.attachmentPath.endsWith('.3gp')|| attachment.attachmentPath.endsWith('.3g2')|| attachment.attachmentPath.endsWith('.mpeg')));
                        setVideoAttachmentDialog(true);
                    }} />
                )}
                {(documentsToDownload.length === 0 && !hasImage && !hasVideo) && (
                <Button
                    // icon="pi pi-question"
                    label='-'
                    className="custom-btn-edit"
                    tooltip="No attachment available"
                />
            )}
            </div>
        );
    }

    useEffect(() => {
        if (searchQuery) {
            setFilteredData(
                productList?.filter((item) =>
                    item.productName?.toLowerCase().includes(searchQuery?.toLowerCase())||
                    item.channelName?.toLowerCase().includes(searchQuery?.toLowerCase())||
                    item.processName?.toLowerCase().includes(searchQuery?.toLowerCase())||
                    item.policyPrefix?.toLowerCase().includes(searchQuery?.toLowerCase())

                )
            );
        } else {
            setFilteredData(productList);
        }
    }, [searchQuery, productList,res]);

  
    const accept = async (rowData) => {
        try {
            const response = await axios.delete(`${appUrl.baseUrl}api/Process/DeleteProcessByID/${rowData.processId}/${rowData.channelId}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            });
            setRes(response.data);
            toast.success("Deleted Successfully");
        } catch (error) {
            toast.error("Failed to delete. Please try again.");
            console.error("Error deleting disclaimer:", error);
        }
    };

    const reject = (rowData) => {
        return
    }
    const confirm = (rowData) => {
        confirmDialog({
            header: (
                <div className="custom-header mb-3 mt-3">
                    <i className="pi pi-trash bg-reddd"></i>
                
                </div>
            ),
            message: (
                <strong>Are you sure you want to delete this Process ?</strong>
            ),
            accept: () => accept(rowData),
            reject: () => reject(rowData),
            acceptClassName: 'custom-btn-red' ,
            rejectClassName: 'custom-white-red',
            className: 'center-buttons no-close-button',
        });
    };
    const isAdmin = localStorage.getItem('admin')
    const actionTemplate = (rowData) => {
        return (
            <div>
                <Button icon="pi pi-pencil" className="custom-btn-edit" onClick={() => editAction(rowData)} />
                <Button icon="pi pi-trash" className="custom-btn-delete" onClick={() => { confirm(rowData) }} />
            </div>
        );
    }
    const editAction = async (rowData) => {
        try {
            const response = await axios.get(`${appUrl.baseUrl}api/Process/GetProcessByProcessId/${rowData.processId}/${rowData.channelId}`);
            const data = response.data;
            setEditable(true);
            setRowselect(data);
            setDialog(true);
        } catch (error) {
            error("Error fetching outage data by ID:", error);
            toast.error("Failed to fetch data for editing");
        }
    };
    const downloadDocuments = async (documentPaths) => {
        try {
            for (let i = 0; i < documentPaths.length; i++) {
                const documentPath = documentPaths[i];
                const response = await fetch(`${appUrl.baseUrl}${documentPath}`);
                const blob = await response.blob();
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', '');
                link.setAttribute('target', '_blank');
                link.click();
                URL.revokeObjectURL(url);
                await new Promise(resolve => setTimeout(resolve, 1000)); // Delay each download by 1 second
            }
        } catch (error) {
            console.error("Error downloading documents:", error);
            toast.error("Failed to download documents");
        }
    };


    const onHide = () => {
        setDialog(false)
    }

    const onHideViewAttachment = () => {
        setViewAttachmentDialog(false);
    };
    const showDescription = (description) => {
        setDescriptionContent(description);
        setDescriptionDialog(true);
    };
    const onHideDescriptionDialog = () => {
        setDescriptionDialog(false);
    };


    return (<>
        <ToastContainer />
        <ConfirmDialog />
        <Dialog visible={dialog} onHide={onHide}
            header={editable ? "Edit process Infromation" : "Add process Infromation"}
            style={{ width: "60vw" }}>
            <AddEditProcessInformation dialog={dialog} setRes={setRes} setDialog={setDialog} editable={editable} rowData={rowselect} onHide={onHide} productId2={productId2}/>
        </Dialog>
        <Dialog visible={viewAttachmentDialog} onHide={onHideViewAttachment} header="View Attachment" style={{ width: '50vw' }}>
            {selectedAttachment && <ViewAttachment attachment={selectedAttachment} />}
        </Dialog>
        <Dialog visible={videoAttachmentDialog} onHide={onHideVideoAttachment} header="View Video Attachment" style={{ width: '50vw' }}>
                {selectedVideoAttachment && <CustomVideo videoAttachments={selectedVideoAttachment} />}
            </Dialog>
        <Dialog visible={descriptionDialog} onHide={onHideDescriptionDialog} header="Description" style={{ width: '50vw' }}>
                <CustomDescription description={descriptionContent} />
            </Dialog>

        <div className='grid'>
            <div className="xl:col-10 md:col-8 sm:col-8 col-4">
                {/* <h6>ProductName</h6> */}
            </div>
            {isAdmin === 'true' || isAdmin === true ? (

            <div className="xl:col-2 md:col-4 sm:col-4 col-8" >
                <div >
                    <Button className="custom-btn w80 " label="+ &nbsp; Add New" onClick={() => { setEditable(false); setDialog(true) }} />
                </div>
            </div>
        ) : null}

            <div className='md:col-12 overflowX' >
                <div className='table-custom'>
                    <DataTable
                        value={filteredData}
                        scrollable
                        scrollHeight="500px"
                        rows={7}
                        paginator
                    >
                        <Column field='productName' header="Product " />
                        {/* <Column field='channelName' header="Channel " /> */}
                        <Column header="Channel"  className='pointer' body={(rowData) => (
                                <span title={rowData.channelName}>
                                    {rowData.channelName.length > 15 ? `${rowData.channelName.slice(0, 15)}...` : rowData.channelName}
                                </span>
                            )} />
                        {/* <Column field='processName' header="Process " /> */}
                        <Column header="Process"  className='pointer' body={(rowData) => (
                                <span title={rowData.processName}>
                                    {rowData.processName.length > 15 ? `${rowData.processName.slice(0, 15)}...` : rowData.processName}
                                </span>
                            )} />
                       <Column header="Prefix"  className='pointer' body={(rowData) => (
                                <span title={rowData.policyPrefix}>
                                    {rowData.policyPrefix.length > 15 ? `${rowData.policyPrefix.slice(0, 15)}...` : rowData.policyPrefix}
                                </span>
                            )} />
                        <Column
                                header="Description"
                                body={(rowData) => (
                                    <a
                                        href="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            showDescription(rowData.description);
                                        }}
                                        style={{ cursor: 'pointer', color: '#00b0ab', textDecoration: 'underline' }}
                                    >
                                        View Description
                                    </a>
                                )}
                            />

                        {/* <Column field='policyPrefix' header="Prefix" /> */}
                       
                        <Column field='createdDate' header="Created Date / Time"
                            body={(rowData) => new Date(rowData.createdDate).toLocaleString('en-GB', {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit',
                                hour12: true
                            })} />
                             <Column
                            field="updatedDate"
                            header="Updated Date/Time"
                            body={(rowData) => {
                                if (!rowData.updatedDate) {
                                    return '-';
                                }
                                return new Date(rowData.updatedDate).toLocaleString('en-GB', {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    hour12: true,
                                });
                            }}
                        />
                        <Column body={actionDocument} header="Attachments" style={{ minWidth: '12rem' }}/>
                        {isAdmin === 'true' || isAdmin === true ? (
                                 <Column header="Action" body={actionTemplate} style={{ minWidth: '10rem' }}/>
                                ) : null}
                    </DataTable>
                </div>
            </div>
        </div>
    </>
    );
};

export default ProcessInformation;
