import React, { useState, useEffect } from 'react'
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import appUrl from "../../../../constants/appUrl";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import ViewAttachment from '../../UserZTrainingMaterial/components/ViewAttachement';
import { FilterMatchMode } from "primereact/api";
import { InputText } from 'primereact/inputtext';
import CustomVideo from '../../../components/CustomVideo';
const UserZAppJourney = ({ searchQuery }) => {
    const [appJourneyList, setAppJourneyList] = useState([]);
    const [viewAttachmentDialog, setViewAttachmentDialog] = useState(false);
    const [selectedAttachment, setSelectedAttachment] = useState(null);
    const [filteredData, setFilteredData] = useState([]);
    const [videoAttachmentDialog, setVideoAttachmentDialog] = useState(false);
    const [selectedVideoAttachment, setSelectedVideoAttachment] = useState(null);

    const [expandedRows, setExpandedRows] = useState(null);
    const [expandedId, setExpandedId] = useState('');

    const GetAllAppJournies = async () => {
        try {
            const response = await fetch(`${appUrl.baseUrl}api/AppJourney/GetAllAppJournies`);
            const data = await response.json();
            setAppJourneyList(data);
        } catch (error) {
            error('Error fetching ', error);
        }
    };
    const onHideViewAttachment = () => {
        setViewAttachmentDialog(false);
    };
    const actionEye = (rowData) => {
        return (
            <div>
                <Button icon="pi pi-eye" className="custom-btn-edit" onClick={() => {
                    setSelectedAttachment(rowData.attachmentsModel);
                    setViewAttachmentDialog(true);
                }} />
            </div>
        );
    }

    const allowExpansion = (rowData) => {
        return rowData.length > 0;
    };
    useEffect(() => {
        if (searchQuery) {
            setFilteredData(
                appJourneyList.filter((item) =>
                    item.productName?.toLowerCase().includes(searchQuery?.toLowerCase())
                )
            );
        } else {
            setFilteredData(appJourneyList);
        }
    }, [searchQuery, appJourneyList]);
    const onHideVideoAttachment = () => {
        setVideoAttachmentDialog(false);
    };

    const onRowExpand = (rowData) => {
        setExpandedId(rowData?.data?.productID)
    }
    const actionDocument = (rowData) => {
        const hasDocument = rowData.attachmentsModel.some(attachment => attachment.attachmentPath.endsWith('.docx') || attachment.attachmentPath.endsWith('.pdf')|| attachment.attachmentPath.endsWith('.xlsx'));
        const hasImage = rowData.attachmentsModel.some(attachment => attachment.attachmentPath.endsWith('.png') || attachment.attachmentPath.endsWith('.jpg') || attachment.attachmentPath.endsWith('.jpeg'));
        const hasVideo = rowData.attachmentsModel.some(attachment => attachment.attachmentPath.endsWith('.mp4') || attachment.attachmentPath.endsWith('.mp5') || attachment.attachmentPath.endsWith('.webm')|| attachment.attachmentPath.endsWith('.mov')|| attachment.attachmentPath.endsWith('.3gp')|| attachment.attachmentPath.endsWith('.3g2')|| attachment.attachmentPath.endsWith('.mpeg'));

        return (
            <div>
                {hasDocument && (
                    <Button icon="pi pi-download" className="custom-btn-edit" onClick={() => {
                        const documentPath = rowData.attachmentsModel.find(attachment => attachment.attachmentPath.endsWith('.docx') || attachment.attachmentPath.endsWith('.pdf')|| attachment.attachmentPath.endsWith('.xlsx')).attachmentPath;
                        const isPDF = documentPath.endsWith('.pdf');
                        const fullDocumentPath = `${appUrl.baseUrl}${documentPath}`;
                        downloadDocument(fullDocumentPath, isPDF);
                    }} />
                )}
                {hasImage && (
                    <Button icon="pi pi-eye" className="custom-btn-edit" onClick={() => {
                        setSelectedAttachment(rowData.attachmentsModel.filter(attachment => attachment.attachmentPath.endsWith('.png') || attachment.attachmentPath.endsWith('.jpg') || attachment.attachmentPath.endsWith('.jpeg')));
                        setViewAttachmentDialog(true);
                    }} />
                )}
                {hasVideo && (
                    <Button icon="pi pi-video" className="custom-btn-edit" onClick={() => {
                        setSelectedVideoAttachment(rowData.attachmentsModel.filter(attachment => attachment.attachmentPath.endsWith('.mp4') || attachment.attachmentPath.endsWith('.mp5') || attachment.attachmentPath.endsWith('.webm')|| attachment.attachmentPath.endsWith('.mov')|| attachment.attachmentPath.endsWith('.3gp')|| attachment.attachmentPath.endsWith('.3g2')|| attachment.attachmentPath.endsWith('.mpeg')));
                        setVideoAttachmentDialog(true);
                    }} />
                )}
            </div>
        );
    }


    const downloadDocument = (documentPath) => {
        let link = document.createElement('a');
        link.href = documentPath;
        link.setAttribute('download', '');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const rowExpansionTemplate = (rowData) => {
        return (
            <div className="border">
                <DataTable value={rowData?.appJourneys}>

                    <Column field="" header="" style={{ width: '2.5rem' }} />
                    <Column field="title" header="Title" />
                    <Column field="createdDate" header="Created Date/Time"
                        body={(rowData) => new Date(rowData.createdDate).toLocaleString('en-GB', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                        })}
                    />
                    <Column header="Attachment" body={actionDocument} />
                </DataTable>
            </div>
        );
    };



    useEffect(() => {
        GetAllAppJournies();
    }, []);
    return (
        <div>
            <Dialog visible={viewAttachmentDialog} onHide={onHideViewAttachment} header="View Attachment" style={{ width: '50vw' }}>
                {selectedAttachment && <ViewAttachment attachment={selectedAttachment} />}
            </Dialog>
            <Dialog visible={videoAttachmentDialog} onHide={onHideVideoAttachment} header="View Video Attachment" style={{ width: '50vw' }}>
                {selectedVideoAttachment && <CustomVideo videoAttachments={selectedVideoAttachment} />}
            </Dialog>
            <div className='grid'>
                <div className='md:col-10'>

                    <h5 style={{ fontWeight: "bold" }}>App Journey</h5>
                </div>
                <div className='md:col-2'>
                    {/* 
                        <InputText className="mbl_view w100" value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" /> */}
                </div>



                <div className='md:col-12 ' >
                    <div className="table-custom">
                        <DataTable
                            value={filteredData}
                            scrollable
                            scrollHeight="600px"
                            rows={9}
                            paginator


                        >
                            <Column header="Product " field='productName' className='left-border' />
                            <Column header=" Channel " field='channelName' />
                            <Column field="title" header="Title" />
                            <Column header="Created Date/Time" field='productCreatedDate'
                                body={(rowData) => new Date(rowData.createdDate).toLocaleString('en-GB', {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    hour12: true
                                })} />
                                <Column field="updatedDate" header="Updated Date/Time"
                            body={(rowData) => new Date(rowData.updatedDate).toLocaleString('en-GB', {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit',
                                hour12: true
                            })} />
                            <Column header="Attachments" body={actionDocument} />
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserZAppJourney