import React from 'react'
import { TabView, TabPanel } from 'primereact/tabview';
import AdminDashboardSlider from './AdminDashboardSlider/pages/AdminDashboardSlider';
import Promtions from './Promotions/pages/Promtions';
const Banners = ({searchQuery}) => {
  return (
  
    <>
    <div>
       <div className="">
       
       <TabView>
       
          <TabPanel header="Banners">
             <AdminDashboardSlider searchQuery={searchQuery} />
          </TabPanel>
          <TabPanel header="Promotions">
             <Promtions searchQuery={searchQuery} />
          </TabPanel>
         
         
       </TabView>
    </div>
    </div>
 </>
  )
}

export default Banners