import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import appUrl from "../../../../constants/appUrl";
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { confirmDialog } from 'primereact/confirmdialog';
import { FilterMatchMode } from "primereact/api";
import { InputText } from 'primereact/inputtext';
import ViewAttachment from '../../ProcessInformation/components/ViewAttachment';
import CustomVideo from '../../../components/CustomVideo';
const UserProduct = ({ searchQuery }) => {

    const [dialog, setDialog] = useState();
    const [productList, setProductList] = useState();
    const [viewAttachmentDialog, setViewAttachmentDialog] = useState(false);
    const [selectedAttachment, setSelectedAttachment] = useState(null);
    const [res, setRes] = useState(null)
    const [rowselect, setRowselect] = useState(null)
    const [editable, setEditable] = useState();
    const [videoAttachmentDialog, setVideoAttachmentDialog] = useState(false);
    const [selectedVideoAttachment, setSelectedVideoAttachment] = useState(null);

    const history = useHistory();

    const [filteredData, setFilteredData] = useState([]);
    const GetAllProducts = async () => {
        try {
            const response = await fetch(`${appUrl.baseUrl}api/Product/GetAllProducts`)
            const data = await response.json();
            setProductList(data)

        } catch (error) {

        }
    }



    const actionEye = (rowData) => (
        <div>
            <Button
                icon="pi pi-eye"
                className="custom-btn-edit"
                onClick={() => {
                    setSelectedAttachment(rowData.productAttachment);
                    setViewAttachmentDialog(true);
                }}
            />
        </div>
    );

    useEffect(() => {
        if (searchQuery) {
            setFilteredData(
                productList.filter((item) =>
                    item.productName?.toLowerCase().includes(searchQuery?.toLowerCase())
                )
            );
        } else {
            setFilteredData(productList);
        }
    }, [searchQuery, productList]);


    const onHide = () => {
        setDialog(false)
        setEditable(false)
    }
    const onHideViewAttachment = () => {
        setViewAttachmentDialog(false);
    };
    const editAction = (rowData) => {
        setEditable(true);
        setEditable(rowData);
        setDialog(true);
        setRowselect(rowData)
    }

    const actionTemplate = (rowData) => {
        return (
            <div>

                <Button icon="pi pi-pencil" className="custom-btn-edit" onClick={() => editAction(rowData)} />
                <Button icon="pi pi-trash" className="custom-btn-delete" onClick={() => { confirm(rowData) }} />
            </div>
        );
    }
    const accept = async (rowData) => {
        const response = await axios.delete(`${appUrl.baseUrl}api/Product/DeleteProductByID/${rowData?.productId}`)
        setRes(response)
        toast.success("Delete Succesfully")
    }
    const reject = (rowData) => {
        return
    }
    const confirm = (rowData) => {
        confirmDialog({
            header: 'Delete',
            message: 'Are u sure want to delete data',
            accept: () => accept(rowData),
            reject: () => reject(rowData),
            acceptClassName: 'custom-bg',
            rejectClassName: 'custom-white',
        })
    }
    const viewProduct = (rowData) => {


        history.push({
            pathname: '/AgainstProductProcess',
            state: { data: rowData },
        });
    }
    const onHideVideoAttachment = () => {
        setVideoAttachmentDialog(false);
    };

    const actionDocument = (rowData) => {
        const hasDocument = rowData.productAttachment.some(attachment => attachment.attachmentPath.endsWith('.docx') || attachment.attachmentPath.endsWith('.pdf')|| attachment.attachmentPath.endsWith('.xlsx'));
        const hasImage = rowData.productAttachment.some(attachment => attachment.attachmentPath.endsWith('.png') || attachment.attachmentPath.endsWith('.jpg') || attachment.attachmentPath.endsWith('.jpeg'));
        const hasVideo = rowData.productAttachment.some(attachment => attachment.attachmentPath.endsWith('.mp4') || attachment.attachmentPath.endsWith('.mp5') || attachment.attachmentPath.endsWith('.webm')|| attachment.attachmentPath.endsWith('.mov')|| attachment.attachmentPath.endsWith('.3gp')|| attachment.attachmentPath.endsWith('.3g2')|| attachment.attachmentPath.endsWith('.mpeg'));

        return (
            <div>
                {hasDocument && (
                    <Button icon="pi pi-download" className="custom-btn-edit" onClick={() => {
                        const documentPath = rowData.productAttachment.find(attachment => attachment.attachmentPath.endsWith('.docx') || attachment.attachmentPath.endsWith('.pdf')|| attachment.attachmentPath.endsWith('.xlsx')).attachmentPath;
                        const isPDF = documentPath.endsWith('.pdf');
                        const fullDocumentPath = `${appUrl.baseUrl}${documentPath}`;
                        downloadDocument(fullDocumentPath, isPDF);
                    }} />
                )}
                {hasImage && (
                    <Button icon="pi pi-eye" className="custom-btn-edit" onClick={() => {
                        setSelectedAttachment(rowData.productAttachment.filter(attachment => attachment.attachmentPath.endsWith('.png') || attachment.attachmentPath.endsWith('.jpg') || attachment.attachmentPath.endsWith('.jpeg')));
                        setViewAttachmentDialog(true);
                    }} />
                )}
                {hasVideo && (
                    <Button icon="pi pi-video" className="custom-btn-edit" onClick={() => {
                        setSelectedVideoAttachment(rowData.productAttachment.filter(attachment => attachment.attachmentPath.endsWith('.mp4') || attachment.attachmentPath.endsWith('.mp5') || attachment.attachmentPath.endsWith('.webm')|| attachment.attachmentPath.endsWith('.mov')|| attachment.attachmentPath.endsWith('.3gp')|| attachment.attachmentPath.endsWith('.3g2')|| attachment.attachmentPath.endsWith('.mpeg')));
                        setVideoAttachmentDialog(true);
                    }} />
                )}
            </div>
        );
    }


    const downloadDocument = (documentPath) => {
        let link = document.createElement('a');
        link.href = documentPath;
        link.setAttribute('download', '');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    useEffect(() => {
        GetAllProducts()
    }, [res])
    return (
        <>
            <ToastContainer />
            <Dialog visible={viewAttachmentDialog} onHide={onHideViewAttachment} header="View Attachment" style={{ width: '50vw' }}>
                {selectedAttachment && <ViewAttachment attachment={selectedAttachment} />}
            </Dialog>
            <Dialog visible={videoAttachmentDialog} onHide={onHideVideoAttachment} header="View Video Attachment" style={{ width: '50vw' }}>
                {selectedVideoAttachment && <CustomVideo videoAttachments={selectedVideoAttachment} />}
            </Dialog>
            <div className='grid'>
                <div className="md:col-10">

                    <h5 style={{ fontWeight: "bold" }}>Product</h5>

                </div>
                <div className='md:col-2'>
                    {/* <InputText className="mbl_view w100" value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" /> */}
                </div>

                {/* <div className="md:col-2" >
                        <div >
                            <Button className="custom-btn w100 " label="New Prodcut"  onClick={() => { setEditable(false); setDialog(true) }} />
                        </div>
                    </div> */}

                <div className='md:col-12' >
                    <div className='table-custom'>
                        <DataTable
                            value={filteredData}

                            scrollable
                            scrollHeight="600px"
                            rows={9}
                            paginator
                        >
                            <Column
                                body={(rowData, index) => {
                                    return index.rowIndex + 1;
                                }}
                                header="Sr. No."
                            />
                            <Column header="Product" body={(rowData) => <Button className='product-button' label={rowData.productName} onClick={() => viewProduct(rowData?.productId)} />} />

                            {/* <Column header="Product Channel" field='productChannel' /> */}
                            <Column header="Overview Summary" field='overviewSummary' />
                            <Column header="Terms & Condition" field='termsAndConditions' />
                            <Column field="createdDate" header="Created Date/Time"
                                body={(rowData) => new Date(rowData.createdDate).toLocaleString('en-GB', {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    hour12: true
                                })} />
                            <Column field="updatedDate" header="Updated Date/Time"
                                body={(rowData) => new Date(rowData.updatedDate).toLocaleString('en-GB', {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    hour12: true
                                })} />
                            <Column header="Attachments" body={actionDocument} />

                        </DataTable>
                    </div>
                </div>
            </div>

        </>
    )
}
export default UserProduct