

import React, { useState, useEffect } from 'react'
import { Button } from 'primereact/button'
import { InputText } from "primereact/inputtext";
import { useFormik } from 'formik'
import * as Yup from 'yup';
import axios from 'axios';
import appUrl from "../../../../constants/appUrl";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Dropdown } from 'primereact/dropdown';
const AddEditComplaints = ({ onHide, setDialog, editable, rowData, setRes }) => {
    const [apiRes, setApiRes] = useState();
    const [productList, setProductList] = useState();
    const [selectedFiles, setSelectedFiles] = useState([])
    const validationSchema = Yup.object({
        complaintDescription: Yup.string().required('Required'),
        productId: Yup.string().required('Required'),
        complaintPrefix: Yup.string().required('Required'),

    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            complaintDescription: '',
            productId: '',
            complaintPrefix: '',
            complaintAttachment: []


        },

        onSubmit: async (data) => {
            try {
                let newData = {
                    complaintDescription: data?.complaintDescription,
                    productId: data?.productId,
                    complaintPrefix: data?.complaintPrefix,
                    complaintAttachment: selectedFiles.map(file => ({ attachmentPath: file }))
                }
                const response = await axios.post(`${appUrl.baseUrl}api/Complaint/AddNewComplaint`, newData);
                setApiRes(response);
                setRes(response);
                toast.success("Added Succesfully")
                setDialog(false);
            } catch (error) {
                toast.error("Error occurred!");
            } finally {
                onHide();

            }
        },
    });
    const handleFileUpload = (event) => {
        const files = event.target.files;
        const imagesArray = Array.from(files);
        let newImagesBase64 = [];

        imagesArray.forEach((file) => {
            const reader = new FileReader();
            reader.onload = () => {
                const base64String = reader.result;
                newImagesBase64.push(base64String);

                if (newImagesBase64.length === imagesArray.length) {
                    formik.setFieldValue("complaintAttachment", [
                        ...formik.values.complaintAttachment,
                        ...newImagesBase64,
                    ]);
                }
            };
            setSelectedFiles(newImagesBase64)
            reader.readAsDataURL(file);
        });
    };

    const handleRemoveImage = (indexToRemove) => {
        const updatedGallery = formik.values.complaintAttachment.filter(
            (_, index) => index !== indexToRemove
        );
        formik.setFieldValue("complaintAttachment", updatedGallery);
    };


    const getAllProduct = async () => {

        try {
            const response = await fetch(`${appUrl.baseUrl}api/Product/GetAllProducts`)
            const data = await response.json();
            setProductList(data);
        }
        catch (error) {
            error(error)
        }
    }
    useEffect(() => {
        getAllProduct()
    }, [])

    return (
        <> <ToastContainer />
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid formgrid grid">

                    <div className="field  md:col-4">
                        <label htmlFor="" style={{ fontWeight: "bold" }}>Description <span className='Staric-Custom'> *</span></label>
                        <InputText
                            id="complaintDescription"
                            name="complaintDescription"
                            type="text"
                            value={formik.values.complaintDescription}
                            onChange={formik.handleChange}
                            placeholder='Enter Description'
                        />
                        {formik.touched.complaintDescription && formik.errors.complaintDescription ? <div className='error'>{formik.errors.complaintDescription}</div> : null}
                    </div>
                    <div className="field  md:col-4">
                        <label htmlFor="" style={{ fontWeight: "bold" }}>Prefix <span className='Staric-Custom'> *</span></label>
                        <InputText
                            id="complaintPrefix"
                            name="complaintPrefix"
                            type="text"
                            value={formik.values.complaintPrefix}
                            onChange={formik.handleChange}
                            placeholder='Enter Prefix'
                        />
                        {formik.touched.complaintPrefix && formik.errors.complaintPrefix ? <div className='error'>{formik.errors.complaintPrefix}</div> : null}
                    </div>
                    <div className="field  md:col-4">
                        <label className="mb-2" style={{ fontWeight: "bold" }}>Product Name <span style={{ color: 'red' }}>*</span></label>
                        <Dropdown
                            value={formik.values.productId}
                            optionLabel="productName"
                            name="productId"
                            options={productList}
                            optionValue="productId"
                            placeholder="Select"
                            onChange={formik.handleChange}
                        />
                        {formik.touched.productId && formik.errors.productId ? <div className='error'>{formik.errors.productId}</div> : null}

                    </div>
                    <div className='field md:col-12 responsive-col'>
                        <div>
                            <label htmlFor='' style={{ fontWeight: "bold" }}>
                                Upload Images<span className='asteric '>&nbsp;*</span>
                            </label>
                            <br />
                            <input
                                type='file'
                                onChange={(event) => handleFileUpload(event)}
                                accept='image/*'
                                multiple
                                className='d-none my-3 '
                            />
                        </div>


                        <div className='block mb-2 upload_images multiple-images'>

                            <div className='upload-grid upload-multiple'>
                                {formik.values.complaintAttachment.map((image, index) => (
                                    <div key={index} className='image-preview'>
                                        <img src={image} alt={`Image ${index}`} />
                                        <Button
                                            icon='pi pi-times'
                                            type='button'
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleRemoveImage(index);
                                            }}
                                        />
                                    </div>
                                ))}

                            </div>
                        </div>

                    </div>
                    <div className="col-12 md:col-10"></div>
                    <div className="col-12 md:col-2" >
                        <div className="flex justify-content-center">
                            <Button className="custom-bg" label={editable ? 'Update' : 'Add '} type='submit' />
                        </div>
                    </div>

                </div>


            </form>
        </>
    )
}

export default AddEditComplaints