import React from 'react'

import { TabView, TabPanel } from 'primereact/tabview';
import TableProcess from '../../Channel/components/TableProcess';
import TableComplaintHandling from '../components/TableComplaintHandling';
import ProcessInformation from '../components/ProcessInformation';
import ComplaintHandling from '../components/ComplaintHandling';
import CallCenterFaq from '../components/CallCenterFaq';
import CallCenterVideos from '../components/CallCenterVideos';


const CallCenter = ({searchQuery}) => {
  return (
    <>
      <TabView>
        <TabPanel header="Process Information">
          <ProcessInformation searchQuery={searchQuery} />
        </TabPanel>
        <TabPanel header="Complaint Handling">
          <ComplaintHandling searchQuery={searchQuery}/>
        </TabPanel>
        <TabPanel header="Tutorials and Videos">
          <CallCenterVideos searchQuery={searchQuery} />
        </TabPanel>
        <TabPanel header="FAQs">
          <CallCenterFaq searchQuery={searchQuery}/>
        </TabPanel>
        

      </TabView>
    </>
  )
}

export default CallCenter