import React, { useState, useEffect } from 'react'

import appUrl from "../../../../constants/appUrl";
import TableProcessInformation from './TableProcessInformation';
const ProcessInformation = ({searchQuery}) => {
    const [processList, setProcessList] = useState();

    const GetAllProcess = async () => {
        const token =localStorage.getItem('token')
        try {
            const response = await fetch(`${appUrl.baseUrl}api/Process/GetAllProcess`,
                {
                    method:'GET',
                    headers:{
                        'Authorization':`Bearer ${token}`,
                        'Content-Type':'application/json'
                    }
                }
            );
            const data = await response.json();
            // setComplaintList(data)
            setProcessList(data?.filter(item => item?.channelId === 3))
        }
        catch (error) {

        }
    }
    useEffect(() => {
        GetAllProcess();
    }, []);

    return (
        <TableProcessInformation callCenter={processList} searchQuery={searchQuery} />
    )
}

export default ProcessInformation