import React from 'react'

import { TabView, TabPanel } from 'primereact/tabview';
import TableProcess from '../../Channel/components/TableProcess';
import TableComplaintHandling from '../components/TableComplaintHandling';
import ProcessInformation from '../components/ProcessInformation';
import ComplaintHandling from '../components/ComplaintHandling';
import SocialProcess from '../components/SocialProcess';
import SocialComplaint from '../components/SocialComplaint';
import SocialFaqs from '../components/SocialFaqs';
import SocialVideos from '../components/SocialVideos';


const SocialMedia = ({searchQuery}) => {
  return (
    <>
      <TabView>
        <TabPanel header="Process Information">
          <SocialProcess searchQuery={searchQuery}/>
        </TabPanel>
        <TabPanel header="Complaint Handling">
          <SocialComplaint searchQuery={searchQuery}/>
        </TabPanel>
        <TabPanel header="Tutorials and Videos">
          <SocialVideos searchQuery={searchQuery}/>
        </TabPanel>
        <TabPanel header="FAQs">
          <SocialFaqs searchQuery={searchQuery}/>
        </TabPanel>
       
      </TabView>
    </>
  )
}

export default SocialMedia