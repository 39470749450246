import React, { useState, useEffect } from 'react'
import { TabView, TabPanel } from 'primereact/tabview';
import ComplainCallCenter from './ComplainCallCenter';
import ComplainInApp from './ComplainInApp';
import ComplainSocial from './ComplainSocial';
import appUrl from "../../../../constants/appUrl";
import './custom.css'; 
const ChannelComplains = () => {
  const [callCenter, setCallCenter] = useState([]);
  const [socialMedia, setSocialMedia] = useState([]);
  const [inApp, setInApp] = useState([]);

  const GetAllComplaints = async () => {
    try {
      const response = await fetch(`${appUrl.baseUrl}api/Complaint/GetAllComplaint`);
      const data = await response.json();
     
      setCallCenter(data?.filter(item => item?.channelID === 3))
      setSocialMedia(data?.filter(item => item?.channelID === 1))
      setInApp(data?.filter(item => item?.channelID === 2))
   
    } catch (error) {
      error('Error fetching complaints:', error);
    }
  };
  useEffect(() => {
    GetAllComplaints();
  }, []);
  return (
    <TabView>

      <TabPanel header="Call Center">
        <ComplainCallCenter callCenter={callCenter} />
      </TabPanel>
      <TabPanel header="Social Media">
        <ComplainSocial socialMedia={socialMedia} />
      </TabPanel>
      <TabPanel header="In-App">
        <ComplainInApp inApp={inApp} />
      </TabPanel>

    </TabView>
  )
}

export default ChannelComplains