import React, { useState, useEffect } from 'react';
import { Carousel } from 'primereact/carousel';
import { useHistory } from 'react-router-dom';
import appUrl from '../../constants/appUrl';
import logo from "../../../assets/KbIcons/PRODUCT.png";

export default function Product() {
    const [productList, setProductList] = useState([]);
    const history = useHistory();

    const viewProduct = (rowData) => {
        history.push({
            pathname: '/product',
            state: { data: rowData },
        });
    };

    const responsiveOptions = [
        {
            breakpoint: '1400px',
            numVisible: 3,
            numScroll: 1,
        },
        {
            breakpoint: '1199px',
            numVisible: 2,
            numScroll: 1,
        },
        {
            breakpoint: '767px',
            numVisible: 1,
            numScroll: 1,
        },
        {
            breakpoint: '575px',
            numVisible: 1,
            numScroll: 1,
        },
    ];

    const getAllProducts = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await fetch(`${appUrl.baseUrl}api/Product/GetAllProducts`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setProductList(data);
        } catch (error) {
            console.error('Error fetching products:', error);
        }
    };

    useEffect(() => {
        getAllProducts();
    }, []);

    if (productList.length === 0) {
        return <div>Loading...</div>;
    }

    const productTemplate = (product) => (
        <div className="product-card" key={product.id}>
            <div className="product-card-content">
                <img
                    src={
                        product.productAttachment && product.productAttachment.length > 0
                            ? `${appUrl.baseUrl}/${product.productAttachment[0].attachmentPath}`
                            : logo
                    }
                    className="product-image"
                    alt={product.productName || 'Product Image'}
                    onError={(e) => { e.target.onerror = null; e.target.src = logo; }}
                />
                <h5 className="product-title">{product.productName}</h5>
                <p className="product-description">{product.productDescription}</p>
                <a
                    className="custom-color"
                    onClick={() => viewProduct(product.productId)}
                >
                    View More <i className="pi pi-arrow-right pl-2"></i>
                </a>
            </div>
        </div>
    );

    return (
        <div className="product-carousel mb-3">
            <Carousel
                value={productList}
                numVisible={3}
                numScroll={3}
                responsiveOptions={responsiveOptions}
                itemTemplate={productTemplate}
            />
        </div>
    );
}
