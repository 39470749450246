import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import appUrl from "../../../../constants/appUrl";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Product from '../../../components/Product';

const Products = () => {

    const [productList, setProductList] = useState([]);
    const [imagesList, setImagesList] = useState([]);
    const [imagesBanner, setImagesBanner] = useState([]);
    const [tipsList, setTipsList] = useState([]);

    const [outageList, setOutageList] = useState();
    const settings = {
        // dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: true
    };

    const GetOutageDegradation = async () => {
        try {
            const response = await fetch(`${appUrl.baseUrl}api/OutageDegradation/GetAllOutageDegradation`)
            const data = await response.json();
            setOutageList(data)
        }
        catch (error) {

        }
    }
    const GetComponent = async () => {
        try {
            const response = await fetch(`${appUrl.baseUrl}api/DashboardComponent/GetAllComponents`)
            const data = await response.json();
            setProductList(data)

        } catch (error) {
            error('Error fetching :', error);
        }
    }
    const GetAllImages = async () => {
        try {
            const response = await fetch(`${appUrl.baseUrl}api/ImageSlider/GetAllImages`)
            const data = await response.json();
            setImagesList(data)

        } catch (error) {
            error('Error fetching :', error);
        }
    }
    const GetAllBanners = async () => {
        try {
            const response = await fetch(`${appUrl.baseUrl}api/ImageSlider/GetPromotionImages`)
            const data = await response.json();
            setImagesBanner(data)

        } catch (error) {
            error('Error fetching :', error);
        }
    }

    const GetAllTips = async () => {
        try {
            const response = await fetch(`${appUrl.baseUrl}api/Tips/GetTips`)
            const data = await response.json();
            setTipsList(data)

        } catch (error) {
            error('Error fetching components:', error);
        }
    }
    function stripHtmlTags(html) {
        let tmp = document.createElement("DIV");
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
    }
    const cards = [
        { id: 1, content: "Call Center", para: "Connecting. Resolving. Empowering", path: "/callcenter" },
        { id: 2, content: "Social Media", para: "Seamless. Intuitive. Empowering", path: "/socialmedia" },
        { id: 3, content: "In-App", para: "Connecting. Engaging. Inspiring", path: "/inapp" },
    ];

    useEffect(() => {
         GetAllBanners();
        GetAllImages();

        GetOutageDegradation()
        GetAllTips();
    }, []);


    return (
        <>
            <div className='grid'>
                <div className='md:col-12'>
                    <h5>Welcome to Knowledge Base</h5>
                </div>
                <div className='md:col-8'>
                    <div className='tile-custom' style={{ height: '300px', overflow: 'auto' }}>
                        <Slider {...settings}>
                            {imagesList.map((imagePath, index) => (
                                <div key={index}>
                                    <img src={`${appUrl.baseUrl}/${imagePath.imagePath}`} style={{ width: '100%', height: '250px' }} alt="Attachment" />
                                </div>

                            ))}
                        </Slider>
                    </div>
                </div>
                <div className='md:col-4'>
                    <div className='tile-custom' style={{ height: '300px',  borderTop: '10px solid #00B0AB' }}>
                        <div className='grid'>
                            <div className='md:col-12'>

                            </div>
                            <div className='md:col-4'> <img src={'assets/layout/images/Tip.png'} alt="logo" style={{ width: '90px' }} /></div>
                            <div className='md:col-8'> <h5 className='' style={{ fontWeight: "bold" }}>Tip of The Day</h5>
                                <p className='color-gray'>Worried about your bills? Zindigi,
                                    has you covered!</p>
                            </div>
                        </div>


                        {tipsList.length> 0 &&((
                            <div  className='color-gray' style={{height:'120px',overflow:'auto'}}>
                                <p dangerouslySetInnerHTML={{ __html: tipsList[0].description }}></p>
                            </div>
                        ))}
                        {/* <div>
                            <p className='custom-color' style={{ fontWeight: "bold" }}>Still waiting for Upgrade your account?</p>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className='grid'>


<div className='md:col-4'>
    <h4 className='m-0'>Outages/Degradations</h4>
    <div className='grid tile-custom mt-3' style={{ height: "298px", overflow: "auto" }}>

        {Array.isArray(outageList) && outageList.length > 0 ? (
            outageList.map((outage, index) => (
                <div key={index} className='md:col-12'>
                    <div

                        className="black-custom p-3"
                        style={{
                            borderRadius: '5px',
                            background: outage.status === 'Resolved' ? '#F1FDF9' : '#FFFAF9'
                        }}
                    >
                        <div className='' style={{ height: '85px' }}>
                            <div className='grid'>
                                <div className='md:col-1  pr-0 mt-2'>
                                    <img src={'assets/layout/images/Kb/logo.png'} style={{ width: '20px' }} alt="logo" className='pt-1' />
                                </div>
                                <div className='md:col-5 pl-3 pr-0'>
                                    <div className='flex'>

                                    <h6 className='pt-2 pl-2 m-0'>{outage.channelName?.join(', ')}</h6>
                                    </div>
                                    <p className='pt-2 color-gray'>{outage.productName}</p>
                                </div>
                                <div className='md:col-6 text-right'>
                                    <p className='pt-2 m-0' style={{ fontSize: '12px' }}>
                                        {new Date(outage.createdDate).toLocaleString('en-GB', {
                                            day: '2-digit',
                                            month: '2-digit',
                                            year: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            second: '2-digit',
                                            hour12: true
                                        })}
                                    </p>
                                    <div className='pt-2'>
                                        <button
                                            className=''
                                            style={{
                                                width: "100px",
                                                height: "30px",
                                                borderRadius: '20px',
                                                border: `1px solid ${outage.status === 'Resolved' ? '#4BC099' : '#F44336'}`,
                                                color: outage.status === 'Resolved' ? '#4BC099' : '#F44336',
                                                background: 'none'
                                            }}
                                        >
                                            {outage.status}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))
        ) : (
            <div className='md:col-4'>
                <p>No outages reported.</p>
            </div>
        )}
    </div>
</div>
<div className='md:col-8'>
    <h4 className='m-0'>Promotions</h4>

    <div className='slide-custom mt-3' style={{ overflow: 'auto' }}>
        <Slider {...settings}>
            {imagesBanner.map((imagePath, index) => (
                <div key={index}>
                    <img src={`${appUrl.baseUrl}/${imagePath.imagePath}`} style={{ width: '100%', height: '295px' }} alt="Attachment" />
                </div>

            ))}
        </Slider>
    </div>



</div>
</div>


   
            <div className='grid'>
                <div className='md:col-12'>
                    <h4 className='m-0'>Channel</h4>
                </div>
                {cards.map(card => (
                    <div key={card.id} className='md:col-4'>
                        <Link className="black-custom" to={card.path}>
                            <div className='tile-custom ' style={{ height: "100px", }}>
                                <div className='grid'>
                                    <div className='md:col-2'>


                                        {card.id === 1 && <div className=''> <img src={'assets/layout/images/Kb/CallCenter.svg'} style={{ width: '60px' }} /></div>}
                                        {card.id === 2 && <div className=''> <img src={'assets/layout/images/Kb/SocialMedia.svg'} style={{ width: '60px' }} /></div>}
                                        {card.id === 3 && <div className=''> <img src={'assets/layout/images/Kb/InApp.svg'} style={{ width: '60px' }} /></div>}



                                    </div>
                                    <div className='md:col-8 pl-3 pr-0'>


                                        <h6 className='pt-2 m-0'> {card.content}</h6>
                                        <p className='m-0 color-gray'>{card.para}</p>

                                    </div>
                                    <div className='md:col-2'>
                                        <div className='text-right' style={{ float: "right" }}>
                                            <div className='round'>
                                                <i className='pi pi-external-link custom-color'></i>
                                            </div> {/* <img src={'assets/layout/images/Kb/link.svg'} style={{ width: '40px' }} /> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                ))}

            </div>

            <div className='grid  pb'>
                <div className='md:col-12'>
                    <h4 className='m-0'>Products</h4>
                </div>
                <Product />
            </div>
        </>
    )
}

export default Products;
