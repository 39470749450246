import React, { useState, useEffect } from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import appUrl from "../../../../constants/appUrl";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode } from "primereact/api";
import { InputText } from 'primereact/inputtext';
import Loader from '../../../components/laoder';
const AgainstProductFaq = ({ product, productId2, searchQuery }) => {
    const [faqList, setFaqList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const getFaqById = async (productId) => {
        try {
            setLoading(true);
            const response = await fetch(`${appUrl.baseUrl}api/FAQ/GetFAQByProductId/${productId || productId2}`);
            const data = await response.json();
            setFaqList(data);
            setLoading(false);
        } catch (error) {
            error('Error fetching FAQs:', error);
        }
    };
    useEffect(() => {
        if (searchQuery) {
            setFilteredData(
                faqList.filter((item) =>
                    item.question?.toLowerCase().includes(searchQuery?.toLowerCase())
                )
            );
        } else {
            setFilteredData(faqList);
        }
    }, [searchQuery, faqList]);

    useEffect(() => {
        if (product && product.productId || productId2) {
            getFaqById(product?.productId, productId2);
        }
    }, [product, productId2]);

    return (
        <div className="grid">
            <div className="md:col-10"></div>
            <div className='md:col-2'>

                {/* <InputText className="mbl_view w100" value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" /> */}
            </div>
            {loading && <Loader />}
            <DataTable
                value={filteredData}
                scrollable
                scrollHeight="500px"
                paginator

                rows={7}
            >

                <Column field="question" header="Question" />
                <Column field="answer" header="Answer" />
                <Column field='createdDate' header="Created Date / Time" 
                    body={(rowData) => new Date(rowData.createdDate).toLocaleString('en-GB', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true
                    })} />
                <Column field="updatedDate" header="Updated Date/Time" className='right-border'
                    body={(rowData) => new Date(rowData.updatedDate).toLocaleString('en-GB', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true
                    })} />
            </DataTable>
        </div>

    );
};

export default AgainstProductFaq;
