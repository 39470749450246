import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import ViewAttachment from '../../ComplainHandling/components/ViewAttachment';
const TableFaq = ({ callCenter,searchQuery }) => {

    const [viewAttachmentDialog, setViewAttachmentDialog] = useState(false);
    const [selectedAttachment, setSelectedAttachment] = useState(null);
    const [dialog, setDialog] = useState();
    const [tableData, setTableData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    
    useEffect(() => {
        setTableData(callCenter)
    }, [callCenter])

    const onHideViewAttachment = () => {
        setViewAttachmentDialog(false);
    };
    const onHide = () => {
        setDialog(false)
    }
 

    useEffect(() => {
        if (searchQuery) {
            setFilteredData(
                tableData?.filter((item) =>
                    item.question?.toLowerCase().includes(searchQuery?.toLowerCase())||
                    item.answer?.toLowerCase().includes(searchQuery?.toLowerCase())||
                    item.productName?.toLowerCase().includes(searchQuery?.toLowerCase())
                )
            );
        } else {
            setFilteredData(tableData);
        }
    }, [searchQuery, tableData]);
   

    return (
        <>

            <Dialog visible={viewAttachmentDialog} onHide={onHideViewAttachment} header="View Attachment" style={{ width: '50vw' }}>
                {selectedAttachment && <ViewAttachment attachment={selectedAttachment} />}
            </Dialog>

            <div className=''>


                <div className='grid'>
                    <div className='md:col-12 overflowX' >
                        <div className='table-custom'>
                            <DataTable
                                value={filteredData}
                                scrollable
                                scrollHeight="600px"
                                rows={9}
                                paginator
                            >
                                  <Column field='productName' header="Product"
                                />
                               <Column header="Channel " body={rowData => rowData.channelName?.join(', ')} />
                                 <Column header="Question" className='pointer' body={(rowData) => (
                                <span title={rowData.question}>
                                    {rowData.question.length > 30 ? `${rowData.question.slice(0, 30)}...` : rowData.question}
                                </span>
                            )} />
                            {/* <Column field='Answer' header="Answer" /> */}
                            <Column header="Answer" className='pointer' body={(rowData) => (
                                <span title={rowData.answer}>
                                    {rowData.answer.length > 30 ? `${rowData.answer.slice(0, 30)}...` : rowData.answer}
                                </span>
                            )} />
                          

                            </DataTable>
                        </div>
                    </div>
                </div>

            </div>

        </>
    );
};

export default TableFaq;
