import React, { useState,useRef } from 'react';
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

const UserChat = () => {
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState('');
  const [message, setMessage] = useState("");

  const fileInput = useRef(null);
  const handleButtonClick = (e) => {
    e.preventDefault();
    fileInput.current.click();
};

  const handleSendMessage = () => {
    if (inputText.trim() !== '') {
      const newMessage = {
        id: messages.length + 1,
        text: inputText.trim(),
        sender: 'user' 
      };
      setMessages([...messages, newMessage]);
      setInputText('');
    }
  };

  return (
    <form >
      <div className="flex">
        <div className="flex-grow-1">
          <div className="p-inputgroup">
            <InputText required name="message" onChange={(e) => setMessage(e.target.value)} value={message} placeholder="Message" />
          </div>
        </div>
        <div className="ml-3">
          <div className="p-inputgroup">
            <Button type="submit" icon="pi pi-angle-right" className="p-button-primary" />
          </div>
        </div>
        <div className="ml-3">
          <div className="p-inputgroup">
            <Button icon="pi pi-images" onClick={handleButtonClick} className="p-button-rounded p-button-text" aria-label="Submit" />
          </div>
        </div>
      </div>
    </form>
  );
};

export default UserChat;
