import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { Menubar } from 'primereact/menubar';
import { confirmDialog } from "primereact/confirmdialog";
import { useHistory } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { InputText } from 'primereact/inputtext';
import appUrl from "../src/app/constants/appUrl";
export const AppTopbar = (props, setRes) => {
    const location = useLocation();
    const [currentRouteLabel, setCurrentRouteLabel] = useState('');
    const [disclaimerList, setDisclaimerList] = useState();
    const history = useHistory();
    const [displayName, setDisplayName] = useState('');
    const isAdmin = localStorage.getItem('admin')
    // const token= localStorage.setItem("token", response.data.token);

    const hiddenSearchRoutes = ['/', '/path'];
    const handleSearchInputChange = (event) => {
        const query = event.target.value;
        //    props.setSearchQuery(query);
        props.onSearch(query);
    };

    const items = [
        {
            label: 'Dashboard',
            command: () => history.push('/')

        },
        {
            label: 'Process',
            command: () => history.push('/userprocess')
        },
        {
            label: 'Tutorial and video',
            command: () => history.push('/usersocialmedia')
        },
        {
            label: 'Complaints',
            command: () => history.push('/usercomplaint')
        },
        {
            label: 'FAQs',
            command: () => history.push('/userfaq')
        },

    ];
    const routeLabels = {
        '/dashboard': 'Dashboard',
        '/adminzproduct': 'Product',
        '/adminzapplourney': 'App Journey',
        '/admintipofday': 'Tip Of Day',
        '/adminoutage': 'Outages/Degradations',
        '/admindisclaimer': 'Disclaimer',
        '/admindashboardslider': 'Dashboard Slider',
        '/faq': 'Faqs',
        '/': 'Dashboard',
        '/usersocialmedia': 'Tutorial and Video',
        '/userzappjourney': 'App Journey',
        '/againstproductprocess': 'App Journey',
        '/userztrainingmaterial': 'Training Material',
        '/useroutage': 'Outages/Degradations',
        '/product': 'Product',
        '/userfaq': 'FAQs',
        '/userchat': 'Chat',
        '/usercomplaint': 'Complaints',
        '/userprocess': 'Process',
        '/adminztrainingmaterial': 'Training Material',

    }


    useEffect(() => {
        const currentPath = location.pathname;

        setCurrentRouteLabel(routeLabels[currentPath] || '');
    }, [location, routeLabels]);



    const handleLogout = () => {
        window.localStorage.clear();
        localStorage.removeItem('login');
        localStorage.removeItem('admin');
        localStorage.removeItem('user');
        history.push('./');
    };
    const GetAllDisclaimer = async () => {
        const token = localStorage.getItem('token')
        try {
            const response = await fetch(`${appUrl.baseUrl}api/Disclaimer/GetAllDisclaimers`,
                {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }

                }
            )
            const data = await response.json();
            setDisclaimerList(data)

        }
        catch (error) {

        }
    }



    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.displayName) {
            console.log('Retrieved displayName:', user.displayName);  // Debugging step
            setDisplayName(user.displayName);
        } else {
            console.log('User object or displayName not found in localStorage');
        }
    }, []);

    useEffect(() => {
        GetAllDisclaimer()
    }, [setRes])
    const reject = () => {
        return
    }


    return (
        <div className="layout-topbar">
            <Link className="layout-topbar-logo">
                {/* <span>{currentRouteLabel}</span> */}
                <img src={'assets/layout/images/Kb/Zindigi.svg'} alt="logo" />
            </Link>
            <button
                type="button"
                className="p-link  layout-menu-button layout-topbar-button hidden-big"
                onClick={props.onToggleMenuClick}
            >
                <i className="pi pi-bars" />
            </button>
            <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={props.onMobileTopbarMenuClick}>
            <button className="p-link layout-topbar-button" onClick={handleLogout}>
                        <i className="pi pi-sign-out" />
                        <span >Profile</span>
                    </button>
            </button>
           

            {!hiddenSearchRoutes.includes(location.pathname) && (
                <>
                    <i className='pi pi-search'></i>
                    <InputText className="mbl_view " placeholder="Search" style={{ border: 'none' }}
                        value={props.searchQuery}
                        onChange={handleSearchInputChange} />
                </>
            )}
            <div className='flex hidden-small'>
                <i className='pi pi-exclamation-triangle red'></i>

            </div>

            <span className='pt-1 pl-2 pr-5  hidden-small' style={{ fontWeight: "bold", }}>Disclaimer:</span>
            <marquee className="hidden-small" style={{ width: "600px", height: "40px", paddingTop: "12px", background: "#FBFBFB" }}>
                {disclaimerList && disclaimerList.length > 0 &&
                    disclaimerList.filter(disclaimer => disclaimer.active).map((disclaimer, index) => (
                        <span key={index}>
                            {disclaimer?.disclaimerDescription}

                            {index !== disclaimerList.filter(disclaimer => disclaimer.active).length - 1 && ' '}
                        </span>
                    ))
                }
            </marquee>

            <ul className={classNames("layout-topbar-menu lg:flex origin-top", { 'layout-topbar-menu-mobil e-active': props.mobileTopbarMenuActive })}>
                {/* <li>
                    <button className='p-link layout-topbar-button'>
                        <img src={'assets/layout/images/banner-primeblocks.png'} alt="logo" className='top-img' />
                    </button>

                </li> */}
                <li className='mt-3 pl-2'>

                    {displayName}


                </li>
                <li>
                    <button className="p-link layout-topbar-button" onClick={handleLogout}>
                        <i className="pi pi-sign-out" />
                        <span >Profile</span>
                    </button>
                </li>
            </ul>
        </div>
    );
}
