import React, { useState, useEffect } from 'react';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Dialog } from 'primereact/dialog';
import appUrl from "../../../../constants/appUrl";
import { FilterMatchMode } from "primereact/api";
import axios from 'axios';
import { InputText } from 'primereact/inputtext';
import AddEditFaq from '../../faqs/components/AddEditFaq';
import Loader from '../../../components/laoder';
const ManageFaq = ({ product, productId2, searchQuery }) => {

    const [rowselect, setRowselect] = useState(null)
    const [dialog, setDialog] = useState();
    const [editable, setEditable] = useState();
    const [faqList, setFaqList] = useState();
    const [res, setRes] = useState(null)
    const [loading, setLoading] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const isAdmin = localStorage.getItem('admin')
    const GetFaqById = async (productId, productId2) => {
        const token = localStorage.getItem('token')
        try {
            setLoading(true);
            const response = await fetch(`${appUrl.baseUrl}api/FAQ/GetFAQByProductId/${productId || productId2}`,
                {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            const data = await response.json();
            setFaqList(data);
            setLoading(false);

        } catch (error) {
            error('Error fetching :', error);
        }
    };

    useEffect(() => {
        if (product && product.productId || productId2) {
            GetFaqById(product?.productId, productId2);
        }
    }, [product, productId2, res]);



    const accept = async (rowData) => {
        try {
            
            const response = await axios.delete(`${appUrl.baseUrl}api/FAQ/DeleteFAQByID/${rowData?.id}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            });
            setRes(response.data);
            toast.success("Deleted Successfully");
        } catch (error) {
            toast.error("Failed to delete. Please try again.");
            console.error("Error deleting disclaimer:", error);
        }
    };
    const reject = (rowData) => {
        return
    }
    const confirm = (rowData) => {
        confirmDialog({
            header: (
                <div className="custom-header mb-3 mt-3">
                    <i className="pi pi-trash bg-reddd"></i>

                </div>
            ),
            message: (
                <strong>Are you sure you want to delete this FAQs?</strong>
            ),
            accept: () => accept(rowData),
            reject: () => reject(rowData),
            acceptClassName: 'custom-btn-red',
            rejectClassName: 'custom-white-red',
            className: 'center-buttons no-close-button',
        });
    };

    const actionTemplate = (rowData) => {
        return (
            <div>
                <Button icon="pi pi-pencil" className="custom-btn-edit" onClick={() => editAction(rowData)} />
                <Button icon="pi pi-trash" className="custom-btn-delete" onClick={() => { confirm(rowData) }} />
            </div>
        );
    }
    const editAction = async (rowData) => {
        const token = localStorage.getItem('token')
        try {
            const response = await axios.get(`${appUrl.baseUrl}api/FAQ/GetFAQById/${rowData.id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            const data = response.data;
            setEditable(true);
            setRowselect(data);
            setDialog(true);
        } catch (error) {
            error("Error fetching outage data by ID:", error);
            toast.error("Failed to fetch data for editing");
        }
    };

    const onHide = () => {
        setDialog(false)
    }

    useEffect(() => {
        if (searchQuery) {
            setFilteredData(
                faqList.filter((item) =>
                    item.question?.toLowerCase().includes(searchQuery?.toLowerCase())
                )
            );
        } else {
            setFilteredData(faqList);
        }
    }, [searchQuery, faqList, res]);


    return (
        <>
        <ConfirmDialog />
            <ToastContainer />
            <Dialog visible={dialog} onHide={onHide} header={editable ? "Edit FAQs" : "Add FAQs"} style={{ width: "60vw" }}>
                <AddEditFaq editable={editable} onHide={onHide} rowData={rowselect} productId2={productId2} setRes={setRes} setDialog={setDialog} />
            </Dialog>

            <div className='grid'>
                <div className="xl:col-10 md:col-8 sm:col-8 col-4"></div>
               
                <div className='xl:col-2 md:col-4 sm:col-4 col-8'>
                    {isAdmin === 'true' || isAdmin === true ? (
                        <Button className="custom-btn w80 " label="+ &nbsp; Add New" onClick={() => { setEditable(false); setDialog(true) }} />
                    ) : null}
                </div>

                <div className='md:col-12 overflowX' >
                {loading && <Loader />}
                    <div className='table-custom'>
                        <DataTable
                            value={filteredData}
                            scrollable

                            scrollHeight="500px"
                            rows={7}
                            paginator
                        >
                            <Column field='productName' header="Product" />
                            <Column header="Channel " body={rowData => rowData.channelName?.join(', ')} />
                            {/* <Column field='question' header="Question" /> */}
                            <Column header="Question" className='pointer' body={(rowData) => (
                                <span title={rowData.question}>
                                    {rowData.question.length > 20 ? `${rowData.question.slice(0, 20)}...` : rowData.question}
                                </span>
                            )} />
                            {/* <Column field='Answer' header="Answer" /> */}
                            <Column header="Answer" className='pointer' body={(rowData) => (
                                <span title={rowData.answer}>
                                    {rowData.answer.length > 20 ? `${rowData.answer.slice(0, 20)}...` : rowData.answer}
                                </span>
                            )} />
                            <Column field='createdDate' header="Created Date / Time"
                                body={(rowData) => new Date(rowData.createdDate).toLocaleString('en-GB', {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    hour12: true
                                })} />
                            <Column
                                field="updatedDate"
                                header="Updated Date/Time"
                                body={(rowData) => {
                                    if (!rowData.updatedDate) {
                                        return '-';
                                    }
                                    return new Date(rowData.updatedDate).toLocaleString('en-GB', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        hour12: true,
                                    });
                                }}
                            />
                            {isAdmin === 'true' || isAdmin === true ? (
                                <Column header="Action" body={actionTemplate} style={{ minWidth: '10rem' }}/>
                            ) : null}
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ManageFaq