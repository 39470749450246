import React,{useState,useEffect} from 'react';
import { TabView, TabPanel } from 'primereact/tabview';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import AgainstProductFaq from '../../AgainstProductFaq/pages/AgainstProductFaq';
import AgainstProductComplaint from '../../AgainstProductComplaint/pages/AgainstProductComplaint';
import AgainstProductSocialMedia from '../../AgainstProductSocialMedia/pages/AgainstProductSocialMedia';
import AgainstProductProcessView from './AgainstProductProcessView';

import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import appUrl from '../../../../constants/appUrl';
import AgainstProductOverView from '../../AgainstProductOverView/pages/AgainstProductOverView';

const AgainstProductProcess = ({searchQuery}) => {
   const location = useLocation()
   const productId = location?.state?.data;
   const [productList, setProductList] = useState([]);
   const [selectedProductId, setSelectedProductId] = useState(null);

   const GetProductName = async () => {
      try {
         const response = await fetch(`${appUrl.baseUrl}api/Product/GetAllProducts`);
         const data = await response.json();
         setProductList(data);

      } catch (error) {
         error('Error fetching products:', error);
      }
   };

   useEffect(() => {
      GetProductName();
   }, []);
   const handleGetId = (productId) => {
      setSelectedProductId(productId);
   }
   const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      centerMode: true, 
   };


   return (
      <>
         <div className="card">
         
            <div className=''>
            <TabView>
           
               <TabPanel header="Process Information">
                  <AgainstProductProcessView product={selectedProductId} productId2={productId} searchQuery={searchQuery}/>
               </TabPanel>
               <TabPanel header="Complaint Handling">
                  <AgainstProductComplaint product={selectedProductId} productId2={productId} searchQuery={searchQuery}/>
               </TabPanel>
               <TabPanel header="Tutorial and Video">
                  <AgainstProductSocialMedia product={selectedProductId} productId2={productId} searchQuery={searchQuery}/>
               </TabPanel>
               <TabPanel header="FAQs">
                  <AgainstProductFaq product={selectedProductId} productId2={productId} searchQuery={searchQuery}/>
               </TabPanel>
              
            </TabView>
            </div>
         </div>
      </>
   );
};

export default AgainstProductProcess;
