import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import appUrl from '../../constants/appUrl';

const CustomVideo = ({ videoAttachments }) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
    };

    const renderSlider = () => {
        if (videoAttachments.length > 1) {
            return (
                <Slider {...settings}>
                    {videoAttachments.map((attachment, index) => (
                        <div key={index}>
                            <video key={index} width="100%" controls>
                                <source src={`${appUrl.baseUrl}${attachment.attachmentPath}`} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    ))}
                </Slider>
            );
        } else if (videoAttachments.length === 1) {
            const attachment = videoAttachments[0]; // Extract the single attachment
            return (
                <video key={0} width="100%" controls>
                    <source src={`${appUrl.baseUrl}${attachment.attachmentPath}`} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            );
        } else {
            return <p>No videos available</p>;
        }
    };

    return (
        <div className="">
            {renderSlider()}
        </div>
    );
};

export default CustomVideo;
