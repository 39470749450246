import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { InputText } from "primereact/inputtext";
import { useFormik } from 'formik';
import { InputTextarea } from "primereact/inputtextarea";
import * as Yup from 'yup';
import axios from 'axios';
import appUrl from "../../../../constants/appUrl";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddEditAdminProduct = ({ onHide, setDialog, setRes, editable, rowData }) => {
  const [apiRes, setApiRes] = useState();
  const [channelList, setChannelList] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [productId, setProductId] = useState('');
  const [loading, setLoading] = useState(false);
  const allowedFileExtensions = [
    '.pdf', '.doc', '.docx', '.xls', '.xlsx', '.mp3', '.mp4', '.png', '.jpg', '.webm', '.mpeg','.svg','.mov'
  ];
  const validationSchema = Yup.object({
    productName: Yup.string().required('Required').max(30, 'Name must be 30 characters'),
    productDescription: Yup.string().required('Required'),
   
    // overviewSummary: Yup.string().required('Required'),
    // termsAndConditions: Yup.string().required('Required'),
    productAttachment: Yup.array()
      .min(1, 'At least one attachment is required')
      .required('Required'),
  });


  const formik = useFormik({
    initialValues: {
      productName: '',
      productDescription: '',
      overviewSummary: '',
      termsAndConditions: '',
      productAttachment: [],
    },
    validationSchema: validationSchema,
    onSubmit: async (data) => {
      setLoading(true);
      const token = localStorage.getItem('token');
        if (!token) {
          toast.error("No token found. Please log in again.");
          return;
        }
      try {
        let newData = {
          productName: data.productName,
          overviewSummary: data.overviewSummary,
          termsAndConditions: data.termsAndConditions,
          productDescription: data.productDescription,
          productAttachment: data.productAttachment.map(attachment => ({ attachmentPath: attachment.file }))
        };

        if (editable) {
          let obj = { ...newData, productID: productId };
          const response = await axios.patch(`${appUrl.baseUrl}api/Product/UpdateProduct`, obj, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          });
          setApiRes(response);
          toast.success("Product Updated Successfully");
          setRes(response);
        } else {
          const response = await axios.post(`${appUrl.baseUrl}api/Product/AddNewProduct`, newData, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          });
          setApiRes(response);
          setRes(response);
          toast.success("Product Added Successfully");
        }
        setDialog(false);
      } catch (error) {
        error('Something went wrong', error);
      } finally {
        setLoading(false);
        onHide();
      }
    },
  });

  const getAllChannel = async () => {
    const token =localStorage.getItem('token')
    try {
      const response = await fetch(`${appUrl.baseUrl}api/Channel/GetAllChannels`,
        {
          method:'GET',
          headers:{
              'Authorization':`Bearer ${token}`,
              'Content-Type':'application/json'
          }
      }
  );
      const data = await response.json();
      setChannelList(data);
    } catch (error) {
      error('Something went wrong', error);
    }
  };

  const handleFileUpload = (event) => {
    const files = event.target.files;
    const imagesArray = Array.from(files);
    let newImagesBase64 = [];
    let hasInvalidFile = false; 
    imagesArray.forEach((file) => {
      const fileExtension = file.name.split('.').pop();
      // if (!allowedFileExtensions.includes(`.${fileExtension}`)) {
      //   toast.error(`${file.name} is not a supported file type`);
      //   hasInvalidFile = true;
      //   return;
      // }

      const isImage = file.type.includes('image');
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result;
        newImagesBase64.push({ isImage, file: base64String, name: file.name });

        if (newImagesBase64.length === imagesArray.length) {
          formik.setFieldValue("productAttachment", [
            ...formik.values.productAttachment,
            ...newImagesBase64,
          ]);
          // setSelectedFiles(newImagesBase64);
        }
      };
      reader.readAsDataURL(file);
    });
    if
      (hasInvalidFile) {
      event.target.value = null;
      
    }
  };

  const handleRemoveImage = (indexToRemove) => {
    const updatedGallery = formik.values.productAttachment.filter(
      (_, index) => index !== indexToRemove
    );
    formik.setFieldValue("productAttachment", updatedGallery);
    // setSelectedFiles(updatedGallery);
  };

  useEffect(() => {
    if (editable) {
      formik.setFieldValue('productName', rowData?.productName);
      formik.setFieldValue('overviewSummary', rowData?.overviewSummary);
      formik.setFieldValue('termsAndConditions', rowData?.termsAndConditions);
      formik.setFieldValue('productDescription', rowData?.productDescription);
      setProductId(rowData?.productId);
      if (rowData?.productAttachment) {
        const productAttachmentsWithBaseUrl = rowData.productAttachment.map((attachment) => ({
          isImage: attachment?.attachmentPath.endsWith('.png') || attachment?.attachmentPath.endsWith('.jpg') || attachment?.attachmentPath.endsWith('.jpeg'),
          file: `${attachment?.attachmentPath}`,
          name: attachment?.attachmentPath.split('\\').pop(),
        }));
        formik.setFieldValue('productAttachment', productAttachmentsWithBaseUrl);
        // setSelectedFiles(productAttachmentsWithBaseUrl);
      }
    }
  }, [editable, rowData]);

  useEffect(() => {
    getAllChannel();
  }, []);

  return (
    <>
      <ToastContainer />
      <form onSubmit={formik.handleSubmit}>
        <div className="p-fluid formgrid grid">
          <div className="field  md:col-6">
            <label htmlFor="productName" style={{ fontWeight: "bold" }}>Product <span className='Staric-Custom'> *</span></label>
            <InputText
              id="productName"
              name="productName"
              type="text"
              value={formik.values.productName}
              onChange={formik.handleChange}
              disabled={editable}
            />
            
            {formik.touched.productName && formik.errors.productName ? <div className='error'>{formik.errors.productName}</div> : null}
          </div>
          <div className="field  md:col-6">
            <label htmlFor="productDescription" style={{ fontWeight: "bold" }}>Description <span className='Staric-Custom'> *</span></label>
            <InputText
              id="productDescription"
              name="productDescription"
              type="text"
              value={formik.values.productDescription}
              onChange={formik.handleChange}
            />
            {formik.touched.productDescription && formik.errors.productDescription ? <div className='error'>{formik.errors.productDescription}</div> : null}
          </div>
          <div className="field md:col-12">
            <label htmlFor="overviewSummary" style={{ fontWeight: "bold" }}>Overview Summary </label>
            <InputTextarea
              rows={5} cols={30}
              id="overviewSummary"
              name="overviewSummary"
              value={formik.values.overviewSummary}
              onChange={formik.handleChange}
            />
            {formik.touched.overviewSummary && formik.errors.overviewSummary ? <div className='error'>{formik.errors.overviewSummary}</div> : null}
          </div>
          <div className="field md:col-12">
            <label htmlFor="termsAndConditions" style={{ fontWeight: "bold" }}>Terms And Conditions</label>
            <InputTextarea
              rows={5} cols={30}
              id="termsAndConditions"
              name="termsAndConditions"
              value={formik.values.termsAndConditions}
              onChange={formik.handleChange}
            />
            {formik.touched.termsAndConditions && formik.errors.termsAndConditions ? <div className='error'>{formik.errors.termsAndConditions}</div> : null}
          </div>
          <div className='field md:col-12 responsive-col'>
            <div>
              <label htmlFor='fileUpload' style={{ fontWeight: "bold" }}>
                Upload Attachments<span className='Staric-Custom'> *</span>
              </label>
              <br />
              <input
                id='fileUpload'
                type='file'
                accept='.pdf,.doc,.docx,.xls,.xlsx,.mp3,.mp4,.png,.jpg,.webm,.mpeg'
                onChange={handleFileUpload}
                multiple
                className='d-none my-3'
              />
            </div>
            <div className='block mb-2 upload_images multiple-images'>
              {editable ?
                <div className='upload-grid upload-multiple'>
                  {formik.values.productAttachment.map((attachment, index) => (
                    <div key={index} className='image-preview'>
                      {attachment.isImage ? <img src={attachment?.file?.includes('data:image') ? attachment?.file : appUrl.baseUrl + attachment?.file} alt={`Image ${attachment.name}`} /> : <>{attachment.name}</>}
                      <Button

                        icon='pi pi-times'
                        type='button'
                        className='cross-button'
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRemoveImage(index);
                        }}
                      />
                    </div>
                  ))}
                </div> :
                <div className='upload-grid upload-multiple'>
                  {formik.values.productAttachment.map((attachment, index) => (
                    <div key={index} className='image-preview'>
                      {attachment.isImage ? <img src={attachment.file} alt={`Image ${attachment.name}`} /> : <>{attachment.name}</>}
                      <Button
                        icon='pi pi-times'
                        type='button'
                        className='cross-button'
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRemoveImage(index);
                        }}
                      />
                    </div>
                  ))}
                </div>
              }

            </div>
            {formik.touched.productAttachment && formik.errors.productAttachment ? <div className='error'>{formik.errors.productAttachment}</div> : null}
                   
          </div>
          <div className="col-12 md:col-8"></div>
          <div className="col-12 md:col-2" >
            <div className="flex justify-content-center">
              <Button className="custom-white w100 " label='Cancel' type='button' onClick={onHide} />
            </div>
          </div>
          <div className="col-12 md:col-2">
            <div className="flex justify-content-center">
              <Button className="custom-btn" label={editable ? 'Update' : 'Add '} type='submit' disabled={loading} />
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddEditAdminProduct;
