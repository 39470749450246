import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import appUrl from "../../../../constants/appUrl";
import { Dialog } from 'primereact/dialog';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import AddEditAdminDashboardSlider from '../components/AddEditAdminDashboardSlider';
// import ViewAttachement from '../components/ViewAttachement';
import AddEditPromotion from '../AddEditPromotion';
import { confirmDialog } from 'primereact/confirmdialog';
import Loader from '../../../components/laoder';
import ViewAttachement from '../../AdminDashboardSlider/components/ViewAttachement';
const Promtions = () => {
    const [dialog, setDialog] = useState();
    const [res, setRes] = useState();
    const [editable, setEditable] = useState();
    const [viewAttachmentDialog, setViewAttachmentDialog] = useState(false);
    const [selectedAttachment, setSelectedAttachment] = useState(null);
    const [sliderList, setSliderList] = useState();
    const [loading, setLoading] = useState(false);
    const GetAllSliderPicture = async () => {
        const token =localStorage.getItem('token')
        try {
            setLoading(true);
            const response = await fetch(`${appUrl.baseUrl}api/ImageSlider/GetPromotionImages`,
                {
                    method:'GET',
                    headers:{
                        'Authorization':`Bearer ${token}`,
                        'Content-Type':'application/json'
                    }
                }
            )
            const data = await response.json();
            setSliderList(data)
            setLoading(false);
        } catch (error) {

        }
    }

    const actionEye = (rowData) => {
        return (
            <div>
                <Button icon="pi pi-eye" className="custom-btn-edit" onClick={() => {
                    setSelectedAttachment(rowData.imagePath);
                    setViewAttachmentDialog(true);
                }} />
            </div>
        );
    }
    const onHideViewAttachment = () => {
        setViewAttachmentDialog(false);
    };

        const onHide = () => {
            setDialog(false)
        }
    const actionTemplate = (rowData) => (
        <div>
            {/* <Button icon="pi pi-pencil" className="custom-btn-edit" onClick={() => editAction(rowData)} /> */}
            <Button icon="pi pi-trash" className="custom-btn-delete" onClick={() => { confirm(rowData) }} />
        </div>
    );
   
    const accept = async (rowData) => {
        try {
            const response = await axios.delete(`${appUrl.baseUrl}api/ImageSlider/DeletePromotionImage/${rowData?.imageID}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            });
            setRes(response.data);
            toast.success("Deleted Successfully");
        } catch (error) {
            toast.error("Failed to delete. Please try again.");
            console.error("Error deleting disclaimer:", error);
        }
    };
    const reject = () => { };

    const confirm = (rowData) => {
        confirmDialog({
            header: (
                <div className="custom-header mb-3 mt-3">
                    <i className="pi pi-trash bg-reddd"></i>
                </div>
            ),
            message: (
                <strong>Are you sure you want to delete this Promotion?</strong>
            ),
            accept: () => accept(rowData),
            reject: () => reject(),
            acceptClassName: 'custom-btn-red',
            rejectClassName: 'custom-white-red',
            className: 'center-buttons no-close-button',
        });
    };

    const isAdmin = localStorage.getItem('admin')

    useEffect(() => {
        GetAllSliderPicture()
    }, [res])
    return (
        <>
          <ToastContainer />
            <Dialog visible={viewAttachmentDialog} onHide={onHideViewAttachment} header="Attachment" style={{ width: '50vw' }}>
                {selectedAttachment && <ViewAttachement  attachment={selectedAttachment} />}
            </Dialog>
            <Dialog visible={dialog} onHide={onHide}
                header={editable ? "Edit Promotion" : "Add Promotion"}
                style={{ width: "60vw" }}>
                <AddEditPromotion editable={editable} dialog={dialog} setRes={setRes} setDialog={setDialog}  onHide={onHide} />
            </Dialog>

            <div className='grid'>
            <div className="xl:col-10 md:col-8 sm:col-8 col-4" ></div>
                {isAdmin === 'true' || isAdmin === true ? (
                  
                    <div className="xl:col-2 md:col-4 sm:col-4 col-8" >
                        <div >
                            <Button className="custom-btn w80 " label="+ &nbsp;Add New" onClick={() => { setEditable(false); setDialog(true) }} />
                        </div>
                    </div>
                ) : null
                }
                <div className='md:col-12 overflowX' >
                {loading && <Loader />}
                    <div className='table-custom'>
                        <DataTable
                            value={sliderList}
                            scrollable
                            scrollHeight="500px"
                            rows={8}
                            paginator
                        >
                            <Column
                                body={(rowData, index) => {
                                    return index.rowIndex + 1;
                                }}
                                header="Sr. No."
                            />
                            
                            <Column header="Images" body={actionEye} />
                            <Column field='createdDate' header="Created Date / Time"
                                body={(rowData) => new Date(rowData.createdDate).toLocaleString('en-GB', {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    hour12: true
                                })} />
                         {isAdmin === 'true' || isAdmin === true ? (
                                 <Column header="Action" body={actionTemplate} />
                                ) : null}
                        </DataTable>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Promtions