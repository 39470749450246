import React, { useState, useEffect, useMemo, useRef } from 'react';
import JoditEditor from 'jodit-react';

const CustomDescription = ({ placeholder, description }) => {
    const [content, setContent] = useState(description);
    const editor = useRef(null);

    const config = useMemo(() => ({
        readonly: true,
        placeholder: placeholder || 'Typing',
    }), [placeholder]);

    useEffect(() => {
        setContent(description);
    }, [description]);

    return (
        <div className="p-fluid formgrid grid">
            <div className="field md:col-12">
                <JoditEditor
                    ref={editor}
                    value={content}
                    config={config}
                    tabIndex={1}
                    onBlur={newContent => setContent(newContent)}
                />
            </div>
        </div>
    );
}

export default CustomDescription;
