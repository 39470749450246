import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import appUrl from "../../../constants/appUrl";

const AddEditPromotion = ({ onHide, setDialog, setRes, editable }) => {
  const [apiRes, setApiRes] = useState();
  const [selectedFiles, setSelectedFiles] = useState([])
  const [loading, setLoading] = useState(false);
  const allowedFileExtensions = [
    '.png', '.jpg', '.svg'
  ];
  const validationSchema = Yup.object({
    imageAttachment: Yup.array().required('Required'),
    imageAttachment: Yup.array().min(1, 'At least one attachment is required').required('Required'),

  });

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      imageAttachment: [],
    },
    onSubmit: async (data) => {
      setLoading(true);
      const token = localStorage.getItem('token');
      if (!token) {
        toast.error("No token found. Please log in again.");
        return;
      }
      try {
        let newData = {
          imageAttachment: selectedFiles.map(file => ({ imagePath: file }))
        }
        {
          const response = await axios.post(`${appUrl.baseUrl}api/ImageSlider/AddPromotionImages`, newData, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          });
          setApiRes(response);

          setRes(response);
          toast.success("Added Succesfully")
          setDialog(false);
        }

      }
      catch (error) {
        toast.error("Something went wrong");
      } finally {
        setLoading(false);
        onHide();
      }
    },
  });

  const handleFileUpload = (event) => {
    const files = event.target.files;
    const imagesArray = Array.from(files);
    let newImagesBase64 = [];
    let hasInvalidFile = false;
    imagesArray.forEach((file) => {
      const fileExtension = file.name.split('.').pop();
      if (!allowedFileExtensions.includes(`.${fileExtension}`)) {
        toast.error(`${file.name} is not a supported file type`);
        hasInvalidFile = true;
        return;
      }
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result;
        newImagesBase64.push(base64String);

        if (newImagesBase64.length === imagesArray.length) {
          formik.setFieldValue("imageAttachment", [
            ...formik.values.imageAttachment,
            ...newImagesBase64,
          ]);
        }
      };
      setSelectedFiles(newImagesBase64)
      reader.readAsDataURL(file);
    });
    if
      (hasInvalidFile) {
      event.target.value = null;

    }
  };

  const handleRemoveImage = (indexToRemove) => {
    const updatedFiles = selectedFiles.filter((_, index) => index !== indexToRemove);
    setSelectedFiles(updatedFiles);
    formik.setFieldValue(
      'imageAttachment',
      formik.values.imageAttachment.filter((_, index) => index !== indexToRemove)
    );
  };

  return (
    <>
      <ToastContainer />

      <form onSubmit={formik.handleSubmit}>
        <div className="p-fluid formgrid grid">
          <div className='field md:col-12 responsive-col'>
            <div>
              <label htmlFor='' style={{ fontWeight: "bold" }}>
                Upload Images<span className='asteric '>&nbsp;*</span>
              </label>
              <br />
              <input
                type='file'
                onChange={(event) => handleFileUpload(event)}
                accept='.png,.jpg,'
                multiple
                className='d-none my-3'
              />
            </div>

            <div className='block mb-2 upload_images multiple-images'>

              <div className='upload-grid upload-multiple'>
                {formik.values.imageAttachment.map((image, index) => (
                  <div key={index} className='image-preview'>
                    <img src={image} alt={`Image ${index}`} />
                    <Button
                      icon='pi pi-times'
                      type='button'
                      className='cross-button'
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRemoveImage(index);
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
            {formik.touched.imageAttachment && formik.errors.imageAttachment ? <div className='error'>{formik.errors.imageAttachment}</div> : null}

          </div>

          <div className="col-12 md:col-8"></div>
          <div className="col-12 md:col-2" >
            <div className="flex justify-content-center">
              <Button className="custom-white w100 " label='Cancel' type='button' onClick={onHide} />
            </div>
          </div>
          <div className="col-12 md:col-2" >
            <div className="flex justify-content-center">
              <Button className="custom-btn w100" label={editable ? 'Update' : 'Add '} type='submit' disabled={loading} />
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default AddEditPromotion;

