

import React, { useState, useEffect, useMemo, useRef } from 'react'
import { Button } from 'primereact/button'
import { InputText } from "primereact/inputtext";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { MultiSelect } from 'primereact/multiselect';
import appUrl from "../../../../constants/appUrl";
import { Dropdown } from 'primereact/dropdown';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import JoditEditor from 'jodit-react';
const AddEditProcessInformation = ({ editable, setRes, rowData, setDialog, onHide, placeholder, productId2 }) => {

    const [productList, setProductList] = useState();
    const [apiRes, setApiRes] = useState();
    const [loading, setLoading] = useState(false);
    const [content, setContent] = useState('');
    const [channelList, setChannelList] = useState();
    const [selectedFiles, setSelectedFiles] = useState([])
    const [isChannelSelected, setIsChannelSelected] = useState(false);
    const editor = useRef(null);
    const allowedFileExtensions = [
        '.pdf', '.doc', '.docx', '.xls', '.xlsx', '.mp3', '.mp4', '.png', '.jpg', '.webm', '.mpeg', '.svg','.mov'
    ];
    const validationSchema = Yup.object({
        channelID: Yup.array().min(1, 'Required').of(Yup.string()).required('Required'),
        description: Yup.string().required('Required'),
        processName: Yup.string().required('Required').max(30, 'Process Name must be 30 characters'),
        policyPrefix: Yup.string().required('Required').max(30, 'Policy Prefix must be 30 characters'),
        processAttachments: Yup.array().min(1, 'At least one attachment is required').required('Required'),


    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            description: '',
            productId: productId2 || '',
            processName: '',
            channelID: [],
            policyPrefix: '',
            processAttachments: []

        },

        onSubmit: async (data) => {
            setLoading(true);
            // data.description = content;
            if (!data.productId) {
                data.productId = 0;
            }
            const token = localStorage.getItem('token');
            if (!token) {
                toast.error("No token found. Please log in again.");
                return;
            }
            try {

                const newData = {
                    description: data.description,
                    policyPrefix: data.policyPrefix,
                    productId: data.productId,
                    channelID: data.channelID,
                    processName: data.processName,
                    processAttachments: data.processAttachments.map(attachment => ({ attachmentPath: attachment.file }))
                };

                if (editable) {
                    newData.processId = rowData.processId;
                    newData.channelID = data.channelID.length > 0 ? data.channelID[0] : null;

                    const response = await axios.patch(`${appUrl.baseUrl}api/Process/UpdateProcess`, newData, {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    setApiRes(response);
                    toast.success("updated Succesfully")
                    setRes(response);
                }
                else {
                    newData.channelID = data.channelID;
                    const response = await axios.post(`${appUrl.baseUrl}api/Process/AddNewProcess`, newData, {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    setApiRes(response);
                    toast.success("Added Succesfully")
                    setRes(response);
                }



                setDialog(false);
            } catch (error) {
                toast.error("Something went wrong");
            } finally {
                setLoading(true);
                onHide();


            }
        },

    });
    const config = useMemo(() => ({
        readonly: false,
        placeholder: placeholder || ''
    }), [placeholder]);

    useEffect(() => {
        if (editable) {
            formik.setFieldValue('productId', rowData?.productId);
            formik.setFieldValue('channelID', rowData.channelId?.length > 0 ? rowData?.channelId : []);
            formik.setFieldValue('processName', rowData?.processName);
            formik.setFieldValue('policyPrefix', rowData?.policyPrefix);
            formik.setFieldValue('description', rowData?.description);
            if (rowData?.processAttachments) {
                const processAttachmentssWithBaseUrl = rowData.processAttachments.map((attachment) => ({
                    isImage: attachment?.attachmentPath.endsWith('.png') || attachment?.attachmentPath.endsWith('.jpg') || attachment?.attachmentPath.endsWith('.jpeg'),
                    file: `${attachment?.attachmentPath}`,
                    name: attachment?.attachmentPath.split('\\').pop(),
                }));
                formik.setFieldValue('processAttachments', processAttachmentssWithBaseUrl);
                // setSelectedFiles(processAttachmentssWithBaseUrl);
            }
        }
    }, [editable, rowData]);

    const handleFileUpload = (event) => {
        const files = event.target.files;
        const imagesArray = Array.from(files);
        let newImagesBase64 = [];
        let hasInvalidFile = false;

        imagesArray.forEach((file) => {
            const fileExtension = file.name.split('.').pop();
            // if (!allowedFileExtensions.includes(`.${fileExtension}`)) {
            //     toast.error(`${file.name} is not a supported file type`);
            //     hasInvalidFile = true;
            //     return;
            // }
            const isImage = file.type.includes('image')
            const reader = new FileReader();
            reader.onload = () => {
                const base64String = reader.result;
                newImagesBase64.push({ isImage, file: base64String, name: file.name });

                if (newImagesBase64.length === imagesArray.length) {
                    formik.setFieldValue("processAttachments", [
                        ...formik.values.processAttachments,
                        ...newImagesBase64,
                    ]);
                }
            };
            reader.readAsDataURL(file);
        });
        if
            (hasInvalidFile) {
            event.target.value = null;

        }
    };

    const handleRemoveImage = (indexToRemove) => {
        const updatedGallery = formik.values.processAttachments.filter(
            (_, index) => index !== indexToRemove
        );
        formik.setFieldValue("processAttachments", updatedGallery);
    };
    const getAllProduct = async () => {
        const token = localStorage.getItem('token')
        try {
            const response = await fetch(`${appUrl.baseUrl}api/Product/GetAllProducts`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            })
            const data = await response.json();
            setProductList(data);
        }
        catch (error) {
            error(error)
        }
    }
    const getAllChannel = async () => {
        const token = localStorage.getItem('token')
        try {
            const response = await fetch(`${appUrl.baseUrl}api/Channel/GetAllChannels`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            })
            const data = await response.json();
            setChannelList(data);
        }
        catch (error) {
        }
    }
    useEffect(() => {
        getAllProduct()
        getAllChannel()
    }, [])
    return (
        <>
            <ToastContainer />

            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid formgrid grid">
                    <div className="field  md:col-6">
                        <label className="mb-2" style={{ fontWeight: "bold" }}>Product</label>
                        <Dropdown
                            value={formik.values.productId}
                            optionLabel="productName"
                            name="productId"
                            options={productList}
                            optionValue="productId"
                            placeholder="Select"
                            onChange={formik.handleChange}
                            disabled={editable || productId2 ? true : false}

                        />
                        {/* {formik.touched.productId && formik.errors.productId ? <div className='error'>{formik.errors.productId}</div> : null} */}
                    </div>
                    <div className="field md:col-6">
                        <label className="mb-2" style={{ fontWeight: "bold" }}>
                            Channel<span style={{ color: 'red' }}>*</span>
                        </label>
                        {editable ? (

                            <MultiSelect
                                value={formik.values.channelID}
                                optionLabel="channelName"
                                name="channelID"
                                options={channelList}
                                optionValue="channelID"
                                placeholder="Select"
                                onChange={formik.handleChange}
                                filter
                                disabled
                            />
                        ) : (
                            <MultiSelect
                                value={formik.values.channelID}
                                optionLabel="channelName"
                                name="channelID"
                                options={channelList}
                                optionValue="channelID"
                                placeholder="Select"
                                onChange={formik.handleChange}
                                filter
                            />
                        )}
                        {formik.touched.channelID && formik.errors.channelID ? (
                            <div className="error">{formik.errors.channelID}</div>
                        ) : null}
                    </div>

                    <div className="field  md:col-6">
                        <label htmlFor="" style={{ fontWeight: "bold" }}>Process<span className='Staric-Custom'> *</span></label>
                        <InputText
                            id="processName"
                            name="processName"
                            type="text"
                            value={formik.values.processName}
                            onChange={formik.handleChange}
                        />
                        {formik.touched.processName && formik.errors.processName ? <div className='error'>{formik.errors.processName}</div> : null}
                    </div>

                    <div className="field md:col-6">
                        <label htmlFor="" style={{ fontWeight: "bold" }}>Prefix  <span className='Staric-Custom'> *</span></label>
                        <InputText
                            id="policyPrefix"
                            name="policyPrefix"
                            type="text"
                            value={formik.values.policyPrefix}
                            onChange={formik.handleChange}
                        />
                        {formik.touched.policyPrefix && formik.errors.policyPrefix ? <div className='error'>{formik.errors.policyPrefix}</div> : null}
                    </div>

                    <div className="field md:col-12">
                        <label htmlFor="" style={{ fontWeight: "bold" }}>Description <span className='Staric-Custom'> *</span></label>
                        <JoditEditor
                            ref={editor}
                            value={formik.values.description}
                            config={config}
                            tabIndex={1}
                            onBlur={newContent => setContent(newContent)}
                            onChange={newContent => formik.setFieldValue('description', newContent)}
                        />
                        {formik.touched.description && formik.errors.description ? <div className='error'>{formik.errors.description}</div> : null}
                    </div>
                    <div className='field md:col-12 responsive-col'>
                        <div>
                            <label htmlFor='' style={{ fontWeight: "bold" }}>
                                Upload Attachments<span className='Staric-Custom'> *</span>
                            </label>
                            <br />
                            <input
                                type='file'
                                onChange={(event) => handleFileUpload(event)}
                                // accept='image/*'
                                accept='.pdf,.doc,.docx,.xls,.xlsx,.mp3,.mp4,.png,.jpg,.webm,.mpeg'
                                multiple
                                className='d-none my-3 '
                            />
                        </div>


                        <div className='block mb-2 upload_images multiple-images'>
                            {editable ?
                                <div className='upload-grid upload-multiple'>
                                    {formik.values.processAttachments.map((attachment, index) => (
                                        <div key={index} className='image-preview'>

                                            {attachment.isImage ? <img src={attachment?.file?.includes('data:image') ? attachment?.file : appUrl.baseUrl + attachment?.file} alt={`Image ${attachment.name}`} /> : <>{attachment.name}</>}
                                            <Button
                                                icon='pi pi-times'
                                                type='button'

                                                className='cross-button'
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleRemoveImage(index);
                                                }}
                                            />
                                        </div>
                                    ))}

                                </div>
                                :
                                <div className='upload-grid upload-multiple'>
                                    {formik.values.processAttachments.map((attachment, index) => (
                                        <div key={index} className='image-preview'>

                                            {attachment.isImage ? <img src={attachment.file} alt={`Image ${attachment.name}`} /> : <>{attachment.name}</>}
                                            <Button
                                                icon='pi pi-times'
                                                type='button'

                                                className='cross-button'
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleRemoveImage(index);
                                                }}
                                            />
                                        </div>
                                    ))}

                                </div>
                            }


                        </div>
                        {formik.touched.processAttachments && formik.errors.processAttachments ? <div className='error'>{formik.errors.processAttachments}</div> : null}

                    </div>


                    <div className="col-12 md:col-8"></div>
                    <div className="col-12 md:col-2" >
                        <div className="flex justify-content-center">
                            <Button className="custom-white w100 " label='Cancel' type='button' onClick={onHide} />
                        </div>
                    </div>
                    <div className="col-12 md:col-2" >
                        <div className="flex justify-content-center">
                            <Button className="custom-btn" label={editable ? 'Update' : 'Add '} type='submit' disabled={loading} />
                        </div>
                    </div>

                </div>


            </form>
        </>
    )
}

export default AddEditProcessInformation