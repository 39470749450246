

import React from 'react'
import { Button } from 'primereact/button'
import { InputText } from "primereact/inputtext";
import { useFormik } from 'formik'
import * as Yup from 'yup';
const AddEditNew = () => {
    const validationSchema = Yup.object({
        productName: Yup.string().required('Required'),
        productCategory: Yup.string().required('Required'),
        productAttachment: Yup.string().required('Required'),

    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            productName:  '',
            productCategory: '',
            productAttachment: '',

        },

      

    });
    return (
        <form >
            <div className="p-fluid formgrid grid">

                <div className="field  md:col-4">
                    <label htmlFor="" style={{ fontWeight: "bold" }}>Product Name <span className='Staric-Custom'> *</span></label>
                    <InputText
                        id="productName"
                        name="productName"
                        type="text"
                        value={formik.values.productName}
                        onChange={formik.handleChange}
                    />
                    {formik.touched.productName && formik.errors.productName ? <div className='error'>{formik.errors.productName}</div> : null}
                </div>

                <div className="field md:col-4">
                    <label htmlFor="" style={{ fontWeight: "bold" }}>Product Category <span className='Staric-Custom'> *</span></label>
                    <InputText
                        id="productCategory"
                        name="productCategory"
                        type="text"
                        value={formik.values.productCategory}
                        onChange={formik.handleChange}
                    />
                    {formik.touched.productCategory && formik.errors.productCategory ? <div className='error'>{formik.errors.productCategory}</div> : null}
                </div>
                <div className="field md:col-4">
                    <label htmlFor="" style={{ fontWeight: "bold" }}>Attach Product Pic <span className='Staric-Custom'> *</span></label>
                    <InputText
                        id="productAttachment"
                        name="productAttachment"
                        type="text"
                        value={formik.values.productAttachment}
                        onChange={formik.handleChange}
                    />
                    {formik.touched.productAttachment && formik.errors.productAttachment ? <div className='error'>{formik.errors.productAttachment}</div> : null}
                </div>


                <div className="col-12 md:col-10"></div>
                <div className="col-12 md:col-2" >
                    <div className="flex justify-content-center">
                        <Button className="custom-bg" label={ 'Add '} type='submit' />
                    </div>
                </div>

            </div>


        </form>
    )
}

export default AddEditNew