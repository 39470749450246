import React, { useState, useEffect, useRef, useMemo } from 'react'
import { Button } from 'primereact/button'
import { useFormik } from 'formik'
import * as Yup from 'yup';
import axios from 'axios';
import appUrl from "../../../../constants/appUrl";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Dropdown } from 'primereact/dropdown';
import JoditEditor from 'jodit-react';
const AddEditAdminTip = ({ onHide, setDialog, editable, rowData, setRes, placeholder }) => {

  const [apiRes, setApiRes] = useState();
  const [productList, setProductList] = useState();
  const [content, setContent] = useState('');
  const editor = useRef(null);
  const [loading, setLoading] = useState(false);
  const validationSchema = Yup.object({
    description: Yup.string().required('Required'),
  });
  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      description: '',
      // productId: '',

    },

    onSubmit: async (data) => {
      setLoading(true);
      data.description = content;
      // if (!data.productId) {
      //   data.productId = 0;
      // }
      const token = localStorage.getItem('token');
      if (!token) {
        toast.error("No token found. Please log in again.");
        return;
      }
      try {
        const response = await axios.post(`${appUrl.baseUrl}api/Tips/AddNewTips`, data, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
        setApiRes(response);
        toast.success("Added Succesfully")
        setRes(response);
        setDialog(false);
      } catch (error) {
        toast.error("Something went wrong");
      } finally {
        setLoading(false);
        onHide();

      }
    },
  });

  const getAllProduct = async () => {
    const token = localStorage.getItem('token')
    try {
      const response = await fetch(`${appUrl.baseUrl}api/Product/GetAllProducts`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
      const data = await response.json();
      setProductList(data);
    }
    catch (error) {
    }
  }
  const config = useMemo(() => ({
    readonly: false,
    placeholder: placeholder || 'Start typings...'
  }), [placeholder]);

  useEffect(() => {
    getAllProduct()
  }, [])

  return (
    <>
      <ToastContainer />
      <form onSubmit={formik.handleSubmit}>
        <div className="p-fluid formgrid grid">

          <div className="field md:col-12">
            <label htmlFor="" style={{ fontWeight: "bold" }}>Description <span className='Staric-Custom'> *</span></label>
            <JoditEditor
              ref={editor}
              value={formik.values.description}
              config={config}
              tabIndex={1}
              onBlur={newContent => setContent(newContent)}
              onChange={newContent => formik.setFieldValue('description', newContent)}
            />

            {formik.touched.description && formik.errors.description ? <div className='error'>{formik.errors.description}</div> : null}
          </div>
          <div className="col-12 md:col-8"></div>
          <div className="col-12 md:col-2" >
            <div className="flex justify-content-center">
              <Button className="custom-white w100 " label='Cancel' type='button' onClick={onHide} />
            </div>
          </div>
          <div className="col-12 md:col-2" >
            <div className="flex justify-content-center">
              <Button className="custom-btn" label={editable ? 'Update' : 'Add '} type='submit' disabled={loading} />
            </div>
          </div>

        </div>


      </form>
    </>
  )
}

export default AddEditAdminTip