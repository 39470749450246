import React, { useState, useEffect } from 'react'

import appUrl from "../../../../constants/appUrl";
import TableComplaintHandling from './TableComplaintHandling';
const ComplaintHandling = ({ searchQuery }) => {
    const [complaintList, setComplaintList] = useState();

    const GetAllComplaint = async () => {
        const token =localStorage.getItem('token')
        try {
            const response = await fetch(`${appUrl.baseUrl}api/Complaint/GetAllComplaint`,
                {
                    method:'GET',
                    headers:{
                        'Authorization':`Bearer ${token}`,
                        'Content-Type':'application/json'
                    }
                }
            );
            const data = await response.json();
            setComplaintList(data?.filter(item => item?.channelId === 3))
        }
        catch (error) {

        }
    }
    useEffect(() => {
        GetAllComplaint();
    }, []);

    return (
        <TableComplaintHandling callCenter={complaintList} searchQuery={searchQuery} />
    )
}

export default ComplaintHandling