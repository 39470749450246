import React, { useState, useEffect } from 'react'
import appUrl from "../../../../constants/appUrl";
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { FilterMatchMode } from "primereact/api";
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import ViewAttachment from '../../ProcessInformation/components/ViewAttachment';
import CustomDescription from '../../../components/CustomDescription';
import CustomVideo from '../../../components/CustomVideo';
import Loader from '../../../components/laoder';
const AgainstProductSocialMedia = ({ product, productId2, searchQuery }) => {
  const [socialList, setSocialList] = useState([]);
  const [viewAttachmentDialog, setViewAttachmentDialog] = useState(false);
  const [selectedAttachment, setSelectedAttachment] = useState(null);
  const [descriptionDialog, setDescriptionDialog] = useState(false);
  const [descriptionContent, setDescriptionContent] = useState('');
  const [videoAttachmentDialog, setVideoAttachmentDialog] = useState(false);
  const [selectedVideoAttachment, setSelectedVideoAttachment] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const getSocialById = async (productId, productId2) => {
    try {
      setLoading(true);
      const response = await fetch(`${appUrl.baseUrl}api/Post/GetPostByProductId/${productId || productId2}`);
      const data = await response.json();
      setSocialList(data);
      setLoading(false);
    } catch (error) {
      error('Error fetching :', error);
    }
  };


  const showDescription = (description) => {
    setDescriptionContent(description);
    setDescriptionDialog(true);
  };
  const onHideDescriptionDialog = () => {
    setDescriptionDialog(false);
  };



  useEffect(() => {
    if (searchQuery) {
      setFilteredData(
        socialList.filter((item) =>
          item.postTitle?.toLowerCase().includes(searchQuery?.toLowerCase())
        )
      );
    } else {
      setFilteredData(socialList);
    }
  }, [searchQuery, socialList]);

  const onHideViewAttachment = () => {
    setViewAttachmentDialog(false);
  };


  const actionDocument = (rowData) => {
    const hasDocument = rowData.postAttachments.some(attachment => attachment.attachmentPath.endsWith('.docx') || attachment.attachmentPath.endsWith('.pdf')|| attachment.attachmentPath.endsWith('.xlsx'));
    const hasImage = rowData.postAttachments.some(attachment => attachment.attachmentPath.endsWith('.png') || attachment.attachmentPath.endsWith('.jpg') || attachment.attachmentPath.endsWith('.jpeg'));
    const hasVideo = rowData.postAttachments.some(attachment => attachment.attachmentPath.endsWith('.mp4') || attachment.attachmentPath.endsWith('.mp5') || attachment.attachmentPath.endsWith('.webm') || attachment.attachmentPath.endsWith('.mov') || attachment.attachmentPath.endsWith('.3gp') || attachment.attachmentPath.endsWith('.3g2') || attachment.attachmentPath.endsWith('.mpeg'));

    return (
      <div>
        {hasDocument && (
          <Button icon="pi pi-download" className="custom-btn-edit" onClick={() => {
            const documentPath = rowData.postAttachments.find(attachment => attachment.attachmentPath.endsWith('.docx') || attachment.attachmentPath.endsWith('.pdf')|| attachment.attachmentPath.endsWith('.xlsx')).attachmentPath;
            const isPDF = documentPath.endsWith('.pdf');
            const fullDocumentPath = `${appUrl.baseUrl}${documentPath}`;
            downloadDocument(fullDocumentPath, isPDF);
          }} />
        )}
        {hasImage && (
          <Button icon="pi pi-eye" className="custom-btn-edit" onClick={() => {
            setSelectedAttachment(rowData.postAttachments.filter(attachment => attachment.attachmentPath.endsWith('.png') || attachment.attachmentPath.endsWith('.jpg') || attachment.attachmentPath.endsWith('.jpeg')));
            setViewAttachmentDialog(true);
          }} />
        )}
        {hasVideo && (
          <Button icon="pi pi-video" className="custom-btn-edit" onClick={() => {
            setSelectedVideoAttachment(rowData.postAttachments.filter(attachment => attachment.attachmentPath.endsWith('.mp4') || attachment.attachmentPath.endsWith('.mp5') || attachment.attachmentPath.endsWith('.webm') || attachment.attachmentPath.endsWith('.mov') || attachment.attachmentPath.endsWith('.3gp') || attachment.attachmentPath.endsWith('.3g2') || attachment.attachmentPath.endsWith('.mpeg')));
            setVideoAttachmentDialog(true);
          }} />
        )}
      </div>
    );
  }
  const downloadDocument = (documentPath) => {
    let link = document.createElement('a');
    link.href = documentPath;
    link.setAttribute('download', '');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  const onHideVideoAttachment = () => {
    setVideoAttachmentDialog(false);
  };
  useEffect(() => {
    if (product && product.productId || productId2) {
      getSocialById(product?.productId, productId2);
    }
  }, [product, productId2]);
  return (
    <>
      <Dialog visible={viewAttachmentDialog} onHide={onHideViewAttachment} header="View Attachment" style={{ width: '50vw' }}>
        {selectedAttachment && <ViewAttachment attachment={selectedAttachment} />}
      </Dialog>
      <Dialog visible={videoAttachmentDialog} onHide={onHideVideoAttachment} header="View Video Attachment" style={{ width: '50vw' }}>
        {selectedVideoAttachment && <CustomVideo videoAttachments={selectedVideoAttachment} />}
      </Dialog>
      <Dialog visible={descriptionDialog} onHide={onHideDescriptionDialog} header="Description" style={{ width: '50vw' }}>
        <CustomDescription description={descriptionContent} />
      </Dialog>
      <div className='grid'>
        <div className="md:col-10"></div>
        <div className='md:col-2'>

          {/* <InputText className="mbl_view w100" value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" /> */}
        </div>
        {loading && <Loader />}
        <div className='md:col-12'>
          <DataTable
            value={filteredData}
            scrollable

            scrollHeight="500px"
            rows={7}
            paginator
          >

            <Column header="Product" field='productName' />

            <Column header="Title" field='postTitle' />
            <Column header="Sponser " field='sponserName' />

            <Column
              header="Description"
              body={(rowData) => (
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    showDescription(rowData.postDescription);
                  }}
                  style={{ cursor: 'pointer', color: '#00b0ab', textDecoration: 'underline' }}
                >
                  View Description
                </a>
              )}
            />
            <Column field='createdDate' header="Created Date / Time"
              body={(rowData) => new Date(rowData.createdDate).toLocaleString('en-GB', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: true
              })} />
            <Column field="updatedDate" header="Updated Date/Time"
              body={(rowData) => new Date(rowData.updatedDate).toLocaleString('en-GB', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: true
              })} />


            <Column header="Attachments" body={actionDocument} />
          </DataTable>
        </div>
      </div>
    </>
  )
}

export default AgainstProductSocialMedia