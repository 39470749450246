import React, { useState, useEffect } from 'react'

import appUrl from "../../../../constants/appUrl";
import TableProcessInformation from './TableProcessInformation';
import TableVideos from './TableVideos';
const InAppVideos = ({searchQuery}) => {
    const [postList, setPostList] = useState();

    const GetAllProcess = async () => {
        const token =localStorage.getItem('token')
        try {
            const response = await fetch(`${appUrl.baseUrl}api/Post/GetAllPost`,
                {
                    method:'GET',
                    headers:{
                        'Authorization':`Bearer ${token}`,
                        'Content-Type':'application/json'
                    }
                }
            );
            const data = await response.json();
            setPostList(data?.filter(item => item?.channelID === 2))
        }
        catch (error) {

        }
    }
    useEffect(() => {
        GetAllProcess();
    }, []);

    return (
        <TableVideos callCenter={postList} searchQuery={searchQuery}/>
    )
}

export default InAppVideos