import React, { useState, useEffect } from 'react'
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import appUrl from "../../../../constants/appUrl";
import { Button } from 'primereact/button';
import { FilterMatchMode } from "primereact/api";
import { Dialog } from 'primereact/dialog';
import ViewAttachement from '../components/ViewAttachement';
import { InputText } from 'primereact/inputtext';
import CustomDescription from '../../../components/CustomDescription';
import CustomVideo from '../../../components/CustomVideo';
const UserZTrainingMaterial = ({ searchQuery }) => {
    const [zTrainingList, setZTrainingList] = useState([]);
    const [viewAttachmentDialog, setViewAttachmentDialog] = useState(false);
    const [selectedAttachment, setSelectedAttachment] = useState(null);
    const [expandedId, setExpandedId] = useState('');
    const [expandedRows, setExpandedRows] = useState(null);
    const [descriptionDialog, setDescriptionDialog] = useState(false);
    const [descriptionContent, setDescriptionContent] = useState('');
    const [videoAttachmentDialog, setVideoAttachmentDialog] = useState(false);
    const [selectedVideoAttachment, setSelectedVideoAttachment] = useState(null);

    const [filteredData, setFilteredData] = useState([]);


    const GetAllAppJournies = async () => {
        try {
            const response = await fetch(`${appUrl.baseUrl}api/TrainingMaterial/GetAllTrainingMaterial`);
            const data = await response.json();
            setZTrainingList(data);
        } catch (error) {
            error('Error fetching ', error);
        }
    };
    const onHideViewAttachment = () => {
        setViewAttachmentDialog(false);
    };

    const allowExpansion = (rowData) => {
        return rowData.length > 0;
    };

    useEffect(() => {
        if (searchQuery) {
            setFilteredData(
                zTrainingList.filter((item) =>
                    item.productName?.toLowerCase().includes(searchQuery?.toLowerCase())
                )
            );
        } else {
            setFilteredData(zTrainingList);
        }
    }, [searchQuery, zTrainingList]);

    const onRowExpand = (rowData) => {
        setExpandedId(rowData?.data?.productID)
    }
    const stripHtmlTags = (html) => {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    };
    const onHideVideoAttachment = () => {
        setVideoAttachmentDialog(false);
    };


    const actionDocument = (rowData) => {
        const hasDocument = rowData.trainingAttachment.some(attachment => attachment.attachmentPath.endsWith('.docx')|| attachment.attachmentPath.endsWith('.xlsx') || attachment.attachmentPath.endsWith('.pdf'));
        const hasImage = rowData.trainingAttachment.some(attachment => attachment.attachmentPath.endsWith('.png') || attachment.attachmentPath.endsWith('.jpg') || attachment.attachmentPath.endsWith('.jpeg'));
        const hasVideo = rowData.trainingAttachment.some(attachment => attachment.attachmentPath.endsWith('.mp4') || attachment.attachmentPath.endsWith('.mp5') || attachment.attachmentPath.endsWith('.webm')|| attachment.attachmentPath.endsWith('.mov')|| attachment.attachmentPath.endsWith('.3gp')|| attachment.attachmentPath.endsWith('.3g2')|| attachment.attachmentPath.endsWith('.mpeg'));

        return (
            <div>
                {hasDocument && (
                    <Button icon="pi pi-download" className="custom-btn-edit" onClick={() => {
                        const documentPath = rowData.trainingAttachment.find(attachment => attachment.attachmentPath.endsWith('.docx') || attachment.attachmentPath.endsWith('.pdf')|| attachment.attachmentPath.endsWith('.xlsx')).attachmentPath;
                        const isPDF = documentPath.endsWith('.pdf');
                        const fullDocumentPath = `${appUrl.baseUrl}${documentPath}`;
                        downloadDocument(fullDocumentPath, isPDF);
                    }} />
                )}
                {hasImage && (
                    <Button icon="pi pi-eye" className="custom-btn-edit" onClick={() => {
                        setSelectedAttachment(rowData.trainingAttachment.filter(attachment => attachment.attachmentPath.endsWith('.png') || attachment.attachmentPath.endsWith('.jpg') || attachment.attachmentPath.endsWith('.jpeg')));
                        setViewAttachmentDialog(true);
                    }} />
                )}
                {hasVideo && (
                    <Button icon="pi pi-video" className="custom-btn-edit" onClick={() => {
                        setSelectedVideoAttachment(rowData.trainingAttachment.filter(attachment => attachment.attachmentPath.endsWith('.mp4') || attachment.attachmentPath.endsWith('.mp5') || attachment.attachmentPath.endsWith('.webm')|| attachment.attachmentPath.endsWith('.mov')|| attachment.attachmentPath.endsWith('.3gp')|| attachment.attachmentPath.endsWith('.3g2')|| attachment.attachmentPath.endsWith('.mpeg')));
                        setVideoAttachmentDialog(true);
                    }} />
                )}
            </div>
        );
    }


    const downloadDocument = (documentPath) => {
        let link = document.createElement('a');
        link.href = documentPath;
        link.setAttribute('download', '');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const showDescription = (description) => {
        setDescriptionContent(description);
        setDescriptionDialog(true);
    };
    const onHideDescriptionDialog = () => {
        setDescriptionDialog(false);
    };


    useEffect(() => {
        GetAllAppJournies();
    }, []);


    return (
        <div>
            <Dialog visible={viewAttachmentDialog} onHide={onHideViewAttachment} header="View Attachment" style={{ width: '50vw' }}>
                {selectedAttachment && <ViewAttachement attachment={selectedAttachment} />}
            </Dialog>
            <Dialog visible={videoAttachmentDialog} onHide={onHideVideoAttachment} header="View Video Attachment" style={{ width: '50vw' }}>
                {selectedVideoAttachment && <CustomVideo videoAttachments={selectedVideoAttachment} />}
            </Dialog>
            <Dialog visible={descriptionDialog} onHide={onHideDescriptionDialog} header="Description" style={{ width: '50vw' }}>
                <CustomDescription description={descriptionContent} />
            </Dialog>
            <div className='grid'>

                <div className='md:col-10'>

                    <h5 style={{ fontWeight: "bold" }}>Training Material</h5>
                </div>
                <div className='md:col-2'>

                    {/* <InputText className="mbl_view w100" value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" /> */}
                </div>
                <div className='md:col-12' >
                    <div className='table-customs'>
                        <DataTable
                            value={filteredData}
                            scrollable
                            scrollHeight="600px"
                            rows={9}
                            paginator
                        >
                            <Column header="Product " field='productName' />
                            <Column header="Channel " field='channelName' />
                            <Column field="title" header="Title" />
                            <Column
                                header="Description"
                                body={(rowData) => (
                                    <a
                                        href="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            showDescription(rowData.description);
                                        }}
                                        style={{ cursor: 'pointer', color: '#00b0ab', textDecoration: 'underline' }}
                                    >
                                        View Description
                                    </a>
                                )}
                            />
                            <Column header="Created Date/Time" field='createdDate'
                                body={(rowData) => new Date(rowData.createdDate).toLocaleString('en-GB', {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    hour12: true
                                })}
                            />
                            <Column field="updatedDate" header="Updated Date/Time"
                                body={(rowData) => new Date(rowData.updatedDate).toLocaleString('en-GB', {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    hour12: true
                                })} />
                            <Column header="Attachments" body={actionDocument} />
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserZTrainingMaterial