import React,{useState,useEffect} from 'react'
import { TabView, TabPanel } from 'primereact/tabview';
import appUrl from "../../../../constants/appUrl";
import ProcessCallCenter from './ProcessCallCenter';
import ProcessSocial from './ProcessSocial';
import ProcessInApp from './ProcessInApp';

const ChannelProcess = () => {

  const [callCenter, setCallCenter] = useState([]);
  const [socialMedia, setSocialMedia] = useState([]);
  const [inApp, setInApp] = useState([]);

  const GetAllProcess = async () => {
    try {
      const response = await fetch(`${appUrl.baseUrl}api/Process/GetAllProcess`);
      const data = await response.json();
   
      setCallCenter(data?.filter(item => item?.channelId === 3))
      setSocialMedia(data?.filter(item => item?.channelId === 1))
      setInApp(data?.filter(item => item?.channelId === 2))
    } catch (error) {
    error('Error fetching complaints:', error);
    }
  };


  useEffect(() => {
    GetAllProcess();
  }, []);

  return (
    <TabView>
      <TabPanel header="Call Center"  style={{color:'red'}}>
        <ProcessCallCenter callCenter ={callCenter} />
      </TabPanel>
      <TabPanel header="Social Media">
        <ProcessSocial socialMedia ={socialMedia} />
      </TabPanel>
      <TabPanel header="In-App">
        <ProcessInApp inApp ={inApp} />
      </TabPanel>

    </TabView>
  )
}

export default ChannelProcess