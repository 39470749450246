import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useFormik } from 'formik';
import { Dropdown } from 'primereact/dropdown';
import * as Yup from 'yup';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import appUrl from "../../../../constants/appUrl";
import { useHistory } from 'react-router-dom';
import { MultiSelect } from 'primereact/multiselect';
const AddEditAdminApp = ({ editable, setRes, rowData, setDialog, onHide }) => {
  const [channelList, setChannelList] = useState();
  const [productList, setProductList] = useState();
  const [apiRes, setApiRes] = useState();
  const [selectedFiles, setSelectedFiles] = useState([])
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const allowedFileExtensions = [
    '.pdf', '.doc', '.docx', '.xls', '.xlsx', '.mp3', '.mp4', '.png', '.jpg', '.webm', '.mpeg','.svg','.mov'
  ];
  const validationSchema = Yup.object().shape({
    // productId: Yup.string().required('This is required'),
    channelID: Yup.array().min(1, 'Required').of(Yup.string()).required('Required'),
    title: Yup.string().required('Required').max(30, 'Title must be 30 characters'),
    appJourneyAttachmentDTOs: Yup.array().min(1, 'At least one attachment is required').required('Required'),
  });
  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      productId: '',
      channelID: [],
      title: '',
      appJourneyAttachmentDTOs: []


    },
    onSubmit: async (data) => {
      setLoading(true);
      if (!data.productId) {
        data.productId = 0;
      }
      const token = localStorage.getItem('token');
      if (!token) {
        toast.error("No token found. Please log in again.");
        return;
      }
      try {
        let newData = {
          productId: data?.productId,
          channelID: data?.channelID,
          title: data?.title,
          appJourneyAttachmentDTOs: data.appJourneyAttachmentDTOs.map(attachment => ({ attachmentPath: attachment.file }))
        }
        if (editable) {
          newData.appJourneyID = rowData.appJourneyID;
          const response = await axios.patch(`${appUrl.baseUrl}api/AppJourney/UpdateAppJourney`, newData,{
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          });
          setApiRes(response);
          toast.success("Updated Successfully")
          setRes(response);
        } else {

          const response = await axios.post(`${appUrl.baseUrl}api/AppJourney/AddNewAppJourney`, newData,{
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          });
          setApiRes(response);
          toast.success("Added Succesfully")
          setRes(response);
         
        }

        setDialog(false);
   
      } catch (error) {
        toast.error("Something went wrong");
      } finally {
        setLoading(false);
        onHide();

      }
    },
  });

  const handleFileUpload = (event) => {
    const files = event.target.files;
    const imagesArray = Array.from(files);
    let newImagesBase64 = [];
    let hasInvalidFile = false; 
    imagesArray.forEach((file) => {
      const fileExtension = file.name.split('.').pop();
      // if (!allowedFileExtensions.includes(`.${fileExtension}`)) {
      //   toast.error(`${file.name} is not a supported file type`);
      //   hasInvalidFile = true;
      //   return;
      // }
      const isImage = file.type.includes('image')
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result;
        newImagesBase64.push({ isImage, file: base64String, name: file.name });

        if (newImagesBase64.length === imagesArray.length) {
          formik.setFieldValue("appJourneyAttachmentDTOs", [
            ...formik.values.appJourneyAttachmentDTOs,
            ...newImagesBase64,
          ]);
        }
      };
      // setSelectedFiles(newImagesBase64)
      reader.readAsDataURL(file);
    });
    if
      (hasInvalidFile) {
      event.target.value = null;
      
    }
  };

  const handleRemoveImage = (indexToRemove) => {
    const updatedGallery = formik.values.appJourneyAttachmentDTOs.filter(
      (_, index) => index !== indexToRemove
    );
    formik.setFieldValue("appJourneyAttachmentDTOs", updatedGallery);
  };

  useEffect(() => {
    if (editable) {
      formik.setFieldValue('title', rowData?.title);
      formik.setFieldValue('productId', rowData?.productId);
      formik.setFieldValue('channelID', rowData.channelId?.length > 0 ? rowData?.channelId : []);
      if (rowData?.attachmentsModel) {
        const appJourneyAttachmentDTOsWithBaseUrl = rowData.attachmentsModel.map((attachment) => ({
          isImage: attachment?.attachmentPath.endsWith('.png') || attachment?.attachmentPath.endsWith('.jpg') || attachment?.attachmentPath.endsWith('.jpeg'),
          file: `${attachment?.attachmentPath}`,
          name: attachment?.attachmentPath.split('\\').pop(),
        }));
        formik.setFieldValue('appJourneyAttachmentDTOs', appJourneyAttachmentDTOsWithBaseUrl);
        // setSelectedFiles(appJourneyAttachmentDTOsWithBaseUrl);
      }
    }
  }, [rowData, editable]);



  const getAllChannel = async () => {
    const token =localStorage.getItem('token')

    try {
      const response = await fetch(`${appUrl.baseUrl}api/Channel/GetAllChannels`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
      
      const data = await response.json();
      setChannelList(data);
    }
    catch (error) {

    }
  }
  const getAllProduct = async () => {
    const token =localStorage.getItem('token')
    try {
      const response = await fetch(`${appUrl.baseUrl}api/Product/GetAllProducts`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
    
      const data = await response.json();
      setProductList(data);
    }
    catch (error) {
    }
  }
  useEffect(() => {
    getAllChannel()
    getAllProduct()
  }, [])
  return (
    <>
      <ToastContainer />

      <form onSubmit={formik.handleSubmit}>
        <div className="grid">
          <div className="col-6 md:col-6 lg:col-6 xs:col-12">
            <div className="flex flex-column">
              <label className="mb-2" style={{ fontWeight: "bold" }}>Product Name </label>
              <Dropdown
                value={formik.values.productId}
                optionLabel="productName"
                name="productId"
                options={productList}
                optionValue="productId"
                placeholder="Select"
                disabled={editable}
                onChange={formik.handleChange}
              />
              {/* {formik.touched.productName && formik.errors.productName ? <div className='error'>{formik.errors.productName}</div> : null} */}
            </div>
          </div>
          <div className="col-6 md:col-6 lg:col-6 xs:col-12">
            <div className="flex flex-column">

              <label className="mb-2" style={{ fontWeight: "bold" }}>Channel Name <span style={{ color: 'red' }}>*</span> </label>
              <MultiSelect
                value={formik.values.channelID}
                optionLabel="channelName"
                name="channelID"
                options={channelList}
                optionValue="channelID"
                placeholder="Select"
                disabled={editable}
                onChange={formik.handleChange}
                filter
              />
              {formik.touched.channelID && formik.errors.channelID ? <div className='error'>{formik.errors.channelID}</div> : null}

            </div>
          </div>


          <div className="col-6 md:col-6 lg:col-6 xs:col-12">
            <div className="flex flex-column">
              <label className="mb-2" style={{ fontWeight: "bold" }}>Title <span style={{ color: 'red' }}>*</span></label>
              <InputText
                name="title"
                id="title"
                placeholder="Enter title"
                value={formik.values.title}
                onChange={formik.handleChange}
              />
              {formik.touched.title && formik.errors.title ? <div className='error'>{formik.errors.title}</div> : null}
            </div>
          </div>



          <div className='field md:col-12 responsive-col'>
            <div>
              <label htmlFor='' style={{ fontWeight: "bold" }}>
                Upload Attachments<span style={{ color: 'red' }}>*</span>
              </label>
              <br />
              <input
                type='file'
                onChange={(event) => handleFileUpload(event)}
                accept='.pdf,.doc,.docx,.xls,.xlsx,.mp3,.mp4,.png,.jpg,.webm,.mpeg'
                multiple
                className='d-none my-3 '
              />
            </div>


            <div className='block mb-2 upload_images multiple-images'>
              {editable ?
                <div className='upload-grid upload-multiple'>
                  {/* Image */}
                  {formik.values.appJourneyAttachmentDTOs.map((attachment, index) => (
                    <div key={index} className='image-preview'>

                      {attachment.isImage ?       <img src={attachment?.file?.includes('data:image') ? attachment?.file : appUrl.baseUrl + attachment?.file}alt={`Image ${attachment.name}`} /> : <>{attachment.name}</>}
                      <Button
                        icon='pi pi-times'
                        type='button'
                        className='cross-button'
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRemoveImage(index);
                        }}
                      />
                    </div>
                  ))}
                  {/* file */}
                </div> :
                <div className='upload-grid upload-multiple'>
                  {/* Image */}
                  {formik.values.appJourneyAttachmentDTOs.map((attachment, index) => (
                    <div key={index} className='image-preview'>

                      {attachment.isImage ? <img src={attachment.file} alt={`Image ${attachment.name}`} /> : <>{attachment.name}</>}
                      <Button
                        icon='pi pi-times'
                        type='button'
                        className='cross-button'
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRemoveImage(index);
                        }}
                      />
                    </div>
                  ))}
                
                </div>
              }


            </div>
            {formik.touched.appJourneyAttachmentDTOs && formik.errors.appJourneyAttachmentDTOs ? <div className='error'>{formik.errors.appJourneyAttachmentDTOs}</div> : null}
          </div>

          <div className="col-2 md:col-2 xl:col-12 lg:col-2 text-right">
            <Button className="custom-btn" label={editable ? 'Update' : 'Add New'} type="submit"   disabled={loading}s/>
          </div>
        </div>
      </form>
    </>
  )
}

export default AddEditAdminApp;