import React from 'react'

import { TabView, TabPanel } from 'primereact/tabview';
import TableProcess from '../../Channel/components/TableProcess';
import TableComplaintHandling from '../components/TableComplaintHandling';
import ProcessInformation from '../components/ProcessInformation';
import ComplaintHandling from '../components/ComplaintHandling';
import InAppComplaint from '../components/InAppComplaint';
import InAppprocess from '../components/InAppprocess';
import InAppFaqs from '../components/InAppFaqs';
import InAppVideos from '../components/InAppVideos';


const InApp = ({searchQuery}) => {
  return (
    <>
      <TabView>
        <TabPanel header="Process Information">
          <InAppprocess searchQuery={searchQuery}/>
        </TabPanel>
        <TabPanel header="Complaint Handling">
          <InAppComplaint searchQuery={searchQuery}/>
        </TabPanel>
        <TabPanel header="Tutorials and Videos">
          <InAppVideos searchQuery={searchQuery}/>
        </TabPanel>
        <TabPanel header="FAQs">
          <InAppFaqs searchQuery={searchQuery}/>
        </TabPanel>
       
      </TabView>
    </>
  )
}

export default InApp