import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { InputText } from "primereact/inputtext";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import appUrl from "../../../../constants/appUrl";
import { ToastContainer, toast } from 'react-toastify';
import { Dropdown } from 'primereact/dropdown';
import 'react-toastify/dist/ReactToastify.css';
import { MultiSelect } from 'primereact/multiselect';

const AddEditFaq = ({ editable, setRes, rowData, setDialog, onHide, GetAllFaq, productId2 }) => {
    const [apiRes, setApiRes] = useState();
    const [productList, setProductList] = useState();
    const [channelList, setChannelList] = useState();
    const [faqFields, setFaqFields] = useState([{ question: '', answer: '' }]);
    const [loading, setLoading] = useState(false);

    const addValidationSchema = Yup.object().shape({
        faqs: Yup.array().of(
            Yup.object().shape({
                question: Yup.string().required('Required'),
                answer: Yup.string().required('Required'),
            })
        ).min(1, 'At least one FAQ is required')
    });

    const updateValidationSchema = Yup.object().shape({
        question: Yup.string().required('Question is required'),
        answer: Yup.string().required('Answer is required'),
    });

    const formik = useFormik({
        validationSchema: editable ? updateValidationSchema : addValidationSchema,
        initialValues: {
            productId: productId2 || '',
            channelID: [],
            ...(editable ? { question: '', answer: '' } : { faqs: faqFields }),
        },
        onSubmit: async (data) => {
            setLoading(true);
            const token = localStorage.getItem('token');
            if (!token) {
                toast.error("No token found. Please log in again.");
                return;
            }
            try {
                data.productId = data.productId || null;
                data.channelID = data.channelID.length > 0 ? data.channelID : null;

                if (editable) {
                    data.id = rowData.id;
                    const response = await axios.patch(`${appUrl.baseUrl}api/FAQ/UpdateFAQ`, data, {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    setApiRes(response);
                    toast.success("Updated Successfully");
                    setRes(response);
                } else {
                    const response = await axios.post(`${appUrl.baseUrl}api/FAQ/AddNewFAQ`, data, {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    setApiRes(response);
                    toast.success("Added Successfully");
                    setRes(response);
                }
                setDialog(false);
                onHide();
            } catch (error) {
                toast.error("Something went wrong");
            } finally {
                setLoading(false);
                onHide();
            }
        },
    });

    const getAllProduct = async () => {
        const token = localStorage.getItem('token')
        try {
            const response = await fetch(`${appUrl.baseUrl}api/Product/GetAllProducts`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            const data = await response.json();
            setProductList(data);
        } catch (error) {
            console.error("Error fetching products:", error);
        }
    }

    const getAllChannel = async () => {
        const token = localStorage.getItem('token')
        try {
            const response = await fetch(`${appUrl.baseUrl}api/Channel/GetAllChannels`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            const data = await response.json();
            setChannelList(data);
        } catch (error) {
            console.error("Error fetching channels:", error);
        }
    }

    useEffect(() => {
        if (editable) {
            formik.setFieldValue('productId', rowData?.productID);
            formik.setFieldValue('question', rowData?.question);
            formik.setFieldValue('answer', rowData?.answer);
            formik.setFieldValue('channelID', rowData?.channelId?.length > 0 ? rowData?.channelId : []);
        }
    }, [editable, rowData]);

    useEffect(() => {
        getAllProduct();
        getAllChannel();
    }, []);

    const addFaqField = () => {
        setFaqFields([...faqFields, { question: '', answer: '' }]);
    };

    const removeFaqField = (index) => {
        const newFaqFields = faqFields.filter((_, i) => i !== index);
        setFaqFields(newFaqFields);
        formik.setFieldValue('faqs', newFaqFields);
    };

    const handleFaqChange = (index, field, value) => {
        const newFaqFields = [...faqFields];
        newFaqFields[index][field] = value;
        setFaqFields(newFaqFields);
        formik.setFieldValue('faqs', newFaqFields);
    };

    return (
        <>
            <ToastContainer />
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid formgrid grid">
                    <div className="field md:col-6">
                        <label className="mb-2" style={{ fontWeight: "bold" }}>Product </label>
                        <Dropdown
                            value={formik.values.productId}
                            optionLabel="productName"
                            name="productId"
                            options={productList}
                            optionValue="productId"
                            placeholder="Select"
                            onChange={formik.handleChange}
                            disabled={editable || productId2 ? true : false}
                        />
                    </div>
                    <div className="field md:col-6">
                        <label className="mb-2" style={{ fontWeight: "bold" }}>Channel </label>
                        <MultiSelect
                            value={formik.values.channelID}
                            optionLabel="channelName"
                            name="channelID"
                            options={channelList}
                            optionValue="channelID"
                            placeholder="Select"
                            onChange={formik.handleChange}
                            filter
                        />
                    </div>

                    {editable ?
                        <>
                            <div className="field md:col-12">
                                <label htmlFor="question" style={{ fontWeight: "bold" }}>Question <span className='Staric-Custom'> *</span></label>
                                <InputText
                                    id="question"
                                    name="question"
                                    type="text"
                                    value={formik.values.question}
                                    onChange={formik.handleChange}
                                />
                                {formik.touched.question && formik.errors.question ? <div className='error'>{formik.errors.question}</div> : null}
                            </div>
                            <div className="field md:col-12">
                                <label htmlFor="answer" style={{ fontWeight: "bold" }}>Answer <span className='Staric-Custom'> *</span></label>
                                <InputText
                                    id="answer"
                                    name="answer"
                                    type="text"
                                    value={formik.values.answer}
                                    onChange={formik.handleChange}
                                />
                                {formik.touched.answer && formik.errors.answer ? <div className='error'>{formik.errors.answer}</div> : null}
                            </div>
                        </>
                        :
                        faqFields.map((faq, index) => (
                            <React.Fragment key={index}>
                                <div className="field md:col-12">
                                    <label htmlFor={`faqs.${index}.question`} style={{ fontWeight: "bold" }}>Question <span className='Staric-Custom'> *</span></label>
                                    <InputText
                                        id={`faqs.${index}.question`}
                                        name={`faqs.${index}.question`}
                                        type="text"
                                        value={faq.question}
                                        onChange={(e) => handleFaqChange(index, 'question', e.target.value)}
                                    />
                                    {formik.touched.faqs && formik.errors.faqs?.[index]?.question ? <div className='error'>{formik.errors.faqs[index].question}</div> : null}
                                </div>
                                <div className="field md:col-12">
                                    <label htmlFor={`faqs.${index}.answer`} style={{ fontWeight: "bold" }}>Answer <span className='Staric-Custom'> *</span></label>
                                    <InputText
                                        id={`faqs.${index}.answer`}
                                        name={`faqs.${index}.answer`}
                                        type="text"
                                        value={faq.answer}
                                        onChange={(e) => handleFaqChange(index, 'answer', e.target.value)}
                                    />
                                    {formik.touched.faqs && formik.errors.faqs?.[index]?.answer ? <div className='error'>{formik.errors.faqs[index].answer}</div> : null}
                                </div>
                                {(index === faqFields.length - 1 && index > 0) ? (
                                    <div className="field md:col-1">
                                        <Button type="button" label="-" onClick={() => removeFaqField(index)} className="p-button-danger" />
                                    </div>
                                ) : null}
                                {(index === faqFields.length - 1) ? (
                                    <div className="field md:col-1">
                                        <Button type="button" label="+" onClick={addFaqField} />
                                    </div>
                                ) : null}

                            </React.Fragment>
                        ))}

                    <div className="col-12 md:col-12"></div>
                    <div className="col-12 md:col-8"></div>
                    <div className="col-12 md:col-2" >
                        <div className="flex justify-content-center">
                            <Button className="custom-white w100 " label='Cancel' type='button' onClick={onHide} />
                        </div>
                    </div>
                    <div className="col-12 md:col-2">
                        <div className="flex justify-content-center">
                            <Button className="custom-btn" label={editable ? 'Update' : 'Add '} type='submit' disabled={loading} />
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
}

export default AddEditFaq;
