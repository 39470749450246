import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import appUrl from "../../../../constants/appUrl";
import { Dialog } from 'primereact/dialog';
import ViewAttachment from '../../ComplainHandling/components/ViewAttachment';
const ProcessCallCenter = ({callCenter}) => {

    const [viewAttachmentDialog, setViewAttachmentDialog] = useState(false);
    const [selectedAttachment, setSelectedAttachment] = useState(null);
    const [dialog, setDialog] = useState();
    const [tableData, setTableData] = useState([]);

    useEffect(()=>{
      setTableData(callCenter)
    },[callCenter])

    const onHideViewAttachment = () => {
        setViewAttachmentDialog(false);
    };
    const onHide = () => {
        setDialog(false)
    }
    const actionDocument = (rowData) => {
        const hasDocument = rowData?.processAttachments?.some(attachment => attachment?.attachmentPath?.endsWith('.docx') || attachment?.attachmentPath?.endsWith('.pdf'));
        const hasImage = rowData?.processAttachments?.some(attachment => attachment?.attachmentPath?.endsWith('.png') || attachment?.attachmentPath?.endsWith('.jpg') || attachment?.attachmentPath?.endsWith('.jpeg'));

        return (
            <div>
                {hasDocument && (
                    <Button icon="pi pi-download" className="custom-btn-edit" onClick={() => {
                        const documentPath = rowData.processAttachments.find(attachment => attachment.attachmentPath.endsWith('.docx') || attachment.attachmentPath.endsWith('.pdf')).attachmentPath;
                        const isPDF = documentPath.endsWith('.pdf');
                        const fullDocumentPath = `${appUrl.baseUrl}${documentPath}`;
                        downloadDocument(fullDocumentPath, isPDF);
                    }} />
                )}
                {hasImage && (
                    <Button icon="pi pi-eye" className="custom-btn-edit" onClick={() => {
                        setSelectedAttachment(rowData.processAttachments.filter(attachment => attachment.attachmentPath.endsWith('.png') || attachment.attachmentPath.endsWith('.jpg') || attachment.attachmentPath.endsWith('.jpeg')));
                        setViewAttachmentDialog(true);
                    }} />
                )}
            </div>
        );
    }

    const downloadDocument = (documentPath) => {
        let link = document.createElement('a');
        link.href = documentPath;
        link.setAttribute('download', '');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    
    return (
        <>

            <Dialog visible={viewAttachmentDialog} onHide={onHideViewAttachment} header="View Attachment" style={{ width: '50vw' }}>
                {selectedAttachment && <ViewAttachment attachment={selectedAttachment} />}
            </Dialog>

            <div className=''>


                <div className='grid'>
                    <div className='md:col-12' >
                        <div className='table-custom'>
                            <DataTable
                                value={tableData}
                                scrollable

                                scrollHeight="500px"
                                rows={8}
                                paginator
                            >
                                {/* <Column field='productName' header="Product Name" /> */}
                                <Column field='channelName' header="Channel Name" />
                                <Column field='policyPrefix' header="Policy Prefix" />
                                <Column field='description' header="Description" />
                                <Column field='createdDate' header="Created Date/Time" 
                                  body={(rowData) => new Date(rowData.createdDate).toLocaleString('en-GB', {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                })}  />

                                <Column header="Action" body={actionDocument} />
                            </DataTable>
                        </div>
                    </div>
                </div>

            </div>

        </>
    );
};

export default ProcessCallCenter;
