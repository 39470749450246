import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import appUrl from '../../../../constants/appUrl';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';

import JoditEditor from 'jodit-react';
const AddEditComplaints = ({ onHide, setDialog, editable, rowData, setRes, placeholder, productId2 }) => {
    const [apiRes, setApiRes] = useState();
    const [productList, setProductList] = useState();
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [channelList, setChannelList] = useState();
    const [loading, setLoading] = useState(false);
    const editor = useRef(null);
    const [content, setContent] = useState('');
    const allowedFileExtensions = [
        '.pdf', '.doc', '.docx', '.xls', '.xlsx', '.mp3', '.mp4', '.png', '.jpg', '.webm', '.mpeg', '.svg','.mov'
    ];
    const validationSchema = Yup.object({
        channelID: Yup.array().min(1, 'Required').of(Yup.string()).required('Required'),
        complaintDescription: Yup.string().required('Required'),
        complaintPrefix: Yup.string().required('Required').max(30, 'Policy Prefix must be 30 characters'),
        complaintProcessName: Yup.string().required('Required').max(30, 'Process Name must be 30 characters'),
        complaintAttachment: Yup.array().min(1, 'At least one attachment is required').required('Required'),

    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            complaintDescription: '',
            productId: productId2 || '',
            complaintPrefix: '',
            complaintProcessName: '',
            channelID: [],
            complaintAttachment: [],
        },
        onSubmit: async (data) => {
            setLoading(true);
            // data.complaintDescription = content;
            if (!data.productId) {
                data.productId = 0;
            }
            const token = localStorage.getItem('token');
            if (!token) {
                toast.error("No token found. Please log in again.");
                return;
            }
            try {
                const newData = {
                    complaintDescription: data?.complaintDescription,
                    productId: data?.productId,
                    complaintPrefix: data?.complaintPrefix,
                    complaintProcessName: data?.complaintProcessName,
                    channelId: data?.channelID,
                    complaintAttachment: data.complaintAttachment.map(attachment => ({ attachmentPath: attachment.file }))
                };
                if (editable) {
                    newData.complaintID = rowData.complaintID;
                    newData.channelId = data.channelID.length > 0 ? data.channelID[0] : null;
                    const response = await axios.patch(`${appUrl.baseUrl}api/Complaint/UpdateComplaint`, newData, {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    setApiRes(response);
                    toast.success("updated Succesfully")
                    setRes(response);
                }

                else {
                    newData.channelId = data.channelID;
                    const response = await axios.post(`${appUrl.baseUrl}api/Complaint/AddNewComplaint`, newData, {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    setApiRes(response);
                    setRes(response);
                    toast.success("Added Successfully");
                    setDialog(false);
                }
                setDialog(false);
            } catch (error) {
                toast.error("Something went wrong");
            } finally {
                setLoading(false);
                onHide();
            }
        },
    });
    useEffect(() => {
        if (editable) {
            formik.setFieldValue('productId', rowData?.productID);
            formik.setFieldValue('channelID', rowData.channelId?.length > 0 ? rowData?.channelId : []);
            formik.setFieldValue('complaintProcessName', rowData?.complaintProcessName);
            formik.setFieldValue('complaintPrefix', rowData?.complaintPrefix);
            formik.setFieldValue('complaintDescription', rowData?.complaintDescription);
            if (rowData?.complaintAttachment) {
                const complaintAttachmentWithBaseUrl = rowData.complaintAttachment.map((attachment) => ({
                    isImage: attachment?.attachmentPath.endsWith('.png') || attachment?.attachmentPath.endsWith('.jpg') || attachment?.attachmentPath.endsWith('.jpeg'),
                    file: `${attachment?.attachmentPath}`,
                    name: attachment?.attachmentPath.split('\\').pop(),
                }));
                formik.setFieldValue('complaintAttachment', complaintAttachmentWithBaseUrl);
                // setSelectedFiles(complaintAttachmentWithBaseUrl);
            }
        }
    }, [editable, rowData]);
    const config = useMemo(() => ({
        readonly: false,
        placeholder: placeholder || ''
    }), [placeholder]);

    const handleFileUpload = (event) => {
        const files = event.target.files;
        const imagesArray = Array.from(files);
        let newImagesBase64 = [];
        let hasInvalidFile = false;

        imagesArray.forEach((file) => {
            const fileExtension = file.name.split('.').pop();
            // if (!allowedFileExtensions.includes(`.${fileExtension}`)) {
            //     toast.error(`${file.name} is not a supported file type`);
            //     hasInvalidFile = true;
            //     return;
            // }
            const isImage = file.type.includes('image')
            const reader = new FileReader();
            reader.onload = () => {
                const base64String = reader.result;
                newImagesBase64.push({ isImage, file: base64String, name: file.name });

                if (newImagesBase64.length === imagesArray.length) {
                    formik.setFieldValue("complaintAttachment", [
                        ...formik.values.complaintAttachment,
                        ...newImagesBase64,
                    ]);
                }
            };
            // setSelectedFiles(newImagesBase64)
            reader.readAsDataURL(file);
        });
        if
            (hasInvalidFile) {
            event.target.value = null;

        }
    };
    const handleRemoveImage = (indexToRemove) => {
        const updatedGallery = formik.values.complaintAttachment.filter((_, index) => index !== indexToRemove);
        formik.setFieldValue("complaintAttachment", updatedGallery);
    };

    const getAllProduct = async () => {
        const token = localStorage.getItem('token')
        try {
            const response = await fetch(`${appUrl.baseUrl}api/Product/GetAllProducts`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            const data = await response.json();
            setProductList(data);
        } catch (error) {
            error(error);
        }
    };

    const getAllChannel = async () => {
        const token = localStorage.getItem('token')
        try {
            const response = await fetch(`${appUrl.baseUrl}api/Channel/GetAllChannels`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            const data = await response.json();
            setChannelList(data);
        } catch (error) {
            error(error);
        }
    };

    useEffect(() => {
        getAllProduct();
        getAllChannel();
    }, []);


    return (
        <>
            <ToastContainer />
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid formgrid grid">
                    <div className="field md:col-6">
                        <label className="mb-2" style={{ fontWeight: "bold" }}>Product </label>
                        <Dropdown
                            value={formik.values.productId}
                            optionLabel="productName"
                            name="productId"
                            options={productList}
                            optionValue="productId"
                            placeholder="Select"
                            onChange={formik.handleChange}
                            disabled={editable || productId2 ? true : false}
                        />
                    </div>
                    <div className="field md:col-6">
                        <label className="mb-2" style={{ fontWeight: "bold" }}>
                            Channel<span style={{ color: 'red' }}>*</span>
                        </label>
                        {editable ? (

                            <MultiSelect
                                value={formik.values.channelID}
                                optionLabel="channelName"
                                name="channelID"
                                options={channelList}
                                optionValue="channelID"
                                placeholder="Select"
                                onChange={formik.handleChange}
                                filter
                                disabled
                            />
                        ) : (
                            <MultiSelect
                                value={formik.values.channelID}
                                optionLabel="channelName"
                                name="channelID"
                                options={channelList}
                                optionValue="channelID"
                                placeholder="Select"
                                onChange={formik.handleChange}
                                filter
                            />
                        )}
                        {formik.touched.channelID && formik.errors.channelID ? (
                            <div className="error">{formik.errors.channelID}</div>
                        ) : null}
                    </div>

                    <div className="field  md:col-6">
                        <label htmlFor="" style={{ fontWeight: "bold" }}>Process<span className='Staric-Custom'> *</span></label>
                        <InputText
                            id="complaintProcessName"
                            name="complaintProcessName"
                            type="text"
                            value={formik.values.complaintProcessName}
                            onChange={formik.handleChange}
                        />
                        {formik.touched.complaintProcessName && formik.errors.complaintProcessName ? <div className='error'>{formik.errors.complaintProcessName}</div> : null}
                    </div>

                    <div className="field md:col-6">
                        <label htmlFor="" style={{ fontWeight: "bold" }}>Prefix <span className='Staric-Custom'> *</span></label>
                        <InputText
                            id="complaintPrefix"
                            name="complaintPrefix"
                            type="text"
                            value={formik.values.complaintPrefix}
                            onChange={formik.handleChange}
                            placeholder='Enter Prefix'
                        />
                        {formik.touched.complaintPrefix && formik.errors.complaintPrefix ? <div className='error'>{formik.errors.complaintPrefix}</div> : null}
                    </div>


                    <div className="field md:col-12">
                        <label htmlFor="" style={{ fontWeight: "bold" }}>Description <span className='Staric-Custom'> *</span></label>
                        <JoditEditor
                            ref={editor}
                            value={formik.values.complaintDescription}
                            config={config}
                            tabIndex={1}
                            onBlur={newContent => setContent(newContent)}
                            onChange={newContent => formik.setFieldValue('complaintDescription', newContent)}
                        />
                        {formik.touched.complaintDescription && formik.errors.complaintDescription ? <div className='error'>{formik.errors.complaintDescription}</div> : null}
                    </div>

                    <div className='field md:col-12 responsive-col'>
                        <div>
                            <label htmlFor='' style={{ fontWeight: "bold" }}>
                                Upload Attachments<span className='Staric-Custom'> *</span>
                            </label>
                            <br />
                            <input
                                type='file'
                                onChange={(event) => handleFileUpload(event)}
                                accept='.pdf,.doc,.docx,.xls,.xlsx,.mp3,.mp4,.png,.jpg,.webm,.mpeg'
                                multiple
                                className='d-none my-3 '
                            />
                        </div>
                        <div className='block mb-2 upload_images multiple-images'>
                            {editable ?
                                <div className='upload-grid upload-multiple'>
                                    {formik.values.complaintAttachment.map((attachment, index) => (
                                        <div key={index} className='image-preview'>

                                            {attachment.isImage ? <img src={attachment?.file?.includes('data:image') ? attachment?.file : appUrl.baseUrl + attachment?.file} alt={`Image ${attachment.name}`} /> : <>{attachment.name}</>}
                                            <Button
                                                icon='pi pi-times'
                                                type='button'
                                                className='cross-button'
                                                onClick={() => handleRemoveImage(index)}
                                            />
                                        </div>
                                    ))}
                                </div>
                                :
                                <div className='upload-grid upload-multiple'>
                                    {formik.values.complaintAttachment.map((attachment, index) => (
                                        <div key={index} className='image-preview'>

                                            {attachment.isImage ? <img src={attachment.file} alt={`Image ${attachment.name}`} /> : <>{attachment.name}</>}
                                            <Button
                                                icon='pi pi-times'
                                                type='button'
                                                className='cross-button'
                                                onClick={() => handleRemoveImage(index)}
                                            />
                                        </div>
                                    ))}
                                </div>}
                        </div>
                        {formik.touched.complaintAttachment && formik.errors.complaintAttachment ? <div className='error'>{formik.errors.complaintAttachment}</div> : null}

                    </div>
                    <div className="col-12 md:col-8"></div>
                    <div className="col-12 md:col-2" >
                        <div className="flex justify-content-center">
                            <Button className="custom-white w100 " label='Cancel' type='button' onClick={onHide} />
                        </div>
                    </div>
                    <div className="col-12 md:col-2" >
                        <div className="flex justify-content-center">
                            <Button className="custom-btn" label={editable ? 'Update' : 'Add '} type='submit' disabled={loading} />
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
};

export default AddEditComplaints;
