import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import appUrl from "../../../../constants/appUrl";

const ViewAttachment = ({ attachment }) => {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
    };
    const renderSlider = () => {
        if (attachment.length > 1) {
            return (
                <Slider {...settings}>
                    {attachment.map((imagePath, index) => (
                        <div key={index}>
                            <img src={`${appUrl.baseUrl}/${imagePath.attachmentPath}`} style={{ width: '100%', height: '300px' }} alt="Attachment" />
                        </div>
                    ))}
                </Slider>
            );
        } else if (attachment.length === 1) {
            return (
                <img src={`${appUrl.baseUrl}/${attachment[0].attachmentPath}`} style={{ width: '100%', height: '300px' }} alt="Attachment" />
            );
        } else {
            return <p>No images available</p>;
        }
    };

    return (
        <div className="">
            {renderSlider()}
        </div>
    );
};

export default ViewAttachment;
