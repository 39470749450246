import React from 'react'

import { TabView, TabPanel } from 'primereact/tabview';
import ChannelProcess from '../components/ChannelProcess';
import ChannelComplains from '../components/ChannelComplains';

const Channel = () => {
  return (
    <>
      <TabView>
        <TabPanel header="Process Information">
          <ChannelProcess />
        </TabPanel>
        <TabPanel header="Complaint Handling">
          <ChannelComplains />
        </TabPanel>
      </TabView>
    </>
  )
}

export default Channel