

import React, { useState, useEffect,  } from 'react'
import { Button } from 'primereact/button'
import { InputText } from "primereact/inputtext";
import { useFormik } from 'formik'
import * as Yup from 'yup';
import axios from 'axios';
import appUrl from "../../../../constants/appUrl";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddEditAdminDisclaimer = ({ onHide, setDialog, editable, rowData, setRes}) => {
  const [apiRes, setApiRes] = useState();
  const [loading, setLoading] = useState(false);
  const validationSchema = Yup.object({
    disclaimerDescription: Yup.string().required('Required').max(150, 'Disclaimer description must be 150 characters'),

  });
  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      disclaimerDescription: '',
    },

    onSubmit: async (data) => {
      setLoading(true); 
      const token = localStorage.getItem('token');
      if (!token) {
        toast.error("No token found. Please log in again.");
        return;
      }
      try {
        if (editable) {
          const response = await axios.patch(`${appUrl.baseUrl}api/Disclaimer/UpdateDisclaimer`, data,{
            headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
          });
          setApiRes(response);
          toast.success("updated Succesfully")
          setRes(response);
        } else {
          const response = await axios.post(`${appUrl.baseUrl}api/Disclaimer/AddNewDisclaimer`, data,{
            headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
          });
          setApiRes(response);
          toast.success("Added Succesfully")
          setRes(response);

        }

        setDialog(false);
      } catch (error) {
        toast.error("Error occurred!");
      } finally {
        setLoading(false);
        onHide();

      }
    },
  });
  
  useEffect(() => {
    if(editable)
    {
    formik.setFieldValue('disclaimerDescription', rowData?.disclaimerDescription);
    formik.setFieldValue('disclaimerID', rowData?.disclaimerID);
  }
}, [editable,rowData]);
  return (
    <> <ToastContainer />
      <form onSubmit={formik.handleSubmit}>
        <div className="p-fluid formgrid grid">

          <div className="field  md:col-6">
            <label htmlFor="" style={{ fontWeight: "bold" }}>Disclaimer <span className='Staric-Custom'> *</span></label>
            <InputText
              id="disclaimerDescription"
              name="disclaimerDescription"
              type="text"
              value={formik.values.disclaimerDescription}
              onChange={formik.handleChange}
              placeholder='Enter Disclaimer'
            />
            {formik.touched.disclaimerDescription && formik.errors.disclaimerDescription ? <div className='error'>{formik.errors.disclaimerDescription}</div> : null}
        
          </div>
          
          <div className="col-12 md:col-8"></div>
          <div className="col-12 md:col-2" >
            <div className="flex justify-content-center">
              <Button className="custom-white w100 " label='Cancel' type='button' onClick={onHide} />
            </div>
          </div>
          <div className="col-12 md:col-2" >
            <div className="flex justify-content-center">
              <Button className="custom-btn w100 " label={editable ? 'Update' : 'Add '} type='submit'      disabled={loading} />
            </div>
          </div>

        </div>


      </form>
    </>
  )
}

export default AddEditAdminDisclaimer