import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import AddEditAdminApp from '../components/AddEditAdminApp';
import appUrl from "../../../../constants/appUrl";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import ViewAttachment from '../components/ViewAttachment';
import CustomVideo from '../../../components/CustomVideo';
import Loader from '../../../components/laoder';
const AdminZAppJourney = ({ searchQuery }) => {
    const [editable, setEditable] = useState(false);
    const [rowselect, setRowselect] = useState(null);
    const [appJourneyList, setAppJourneyList] = useState([]);
    const [res, setRes] = useState(null);
    const [loading, setLoading] = useState(false);
    const [dialog, setDialog] = useState(false);
    const [viewAttachmentDialog, setViewAttachmentDialog] = useState(false);
    const [selectedAttachment, setSelectedAttachment] = useState(null);
    const [filteredData, setFilteredData] = useState([]);
    const [videoAttachmentDialog, setVideoAttachmentDialog] = useState(false);
    const [selectedVideoAttachment, setSelectedVideoAttachment] = useState(null);


    const GetAppJourney = async () => {
        const token =localStorage.getItem('token')
        try {
            setLoading(true);
            const response = await fetch(`${appUrl.baseUrl}api/AppJourney/GetAllAppJournies`,
                {
                    method:'GET',
                    headers:{
                        'Authorization':`Bearer ${token}`,
                        'Content-Type':'application/json'
                    }
                }
            );
            const data = await response.json();
            setAppJourneyList(data);
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    const actionTemplate = (rowData) => {
        return (
            <div>
                <Button icon="pi pi-pencil" className="custom-btn-edit" onClick={() => editAction(rowData)} />
                <Button icon="pi pi-trash" className="custom-btn-delete" onClick={() => confirm(rowData)} />
            </div>
        );
    };

    const accept = async (rowData) => {
        try {
            const response = await axios.delete(`${appUrl.baseUrl}api/AppJourney/DeleteAppJourneyByID/${rowData?.appJourneyID}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            });
            setRes(response.data);
            toast.success("Deleted Successfully");
        } catch (error) {
            toast.error("Failed to delete. Please try again.");
            console.error("Error deleting disclaimer:", error);
        }
    };
    const reject = () => {};

    const confirm = (rowData) => {
        confirmDialog({
            header: <div className="custom-header mb-3 mt-3"><i className="pi pi-trash bg-reddd"></i></div>,
            message: <strong>Are you sure you want to delete this App Journey?</strong>,
            accept: () => accept(rowData),
            reject: () => reject(),
            acceptClassName: 'custom-btn-red',
            rejectClassName: 'custom-white-red',
            className: 'center-buttons no-close-button',
        });
    };

    const editAction = async (rowData) => {
        const token =localStorage.getItem('token')
        try {
            const response = await axios.get(`${appUrl.baseUrl}api/AppJourney/GetAppJourneyById/${rowData.appJourneyID}?${rowData.productId}`, {
                headers: {
                  'Authorization': `Bearer ${token}`,
                  'Content-Type': 'application/json'
                }
              });
            
            const data = response.data;
            setEditable(true);
            setRowselect(data);
            setDialog(true);
        } catch (error) {
            console.error("Error fetching App Journey data by ID:", error);
            toast.error("Failed to fetch data for editing");
        }
    };

    const actionDocument = (rowData) => {
        const documentsToDownload = rowData.attachmentsModel.filter(attachment =>
            attachment.attachmentPath.endsWith('.docx') ||
            attachment.attachmentPath.endsWith('.pdf') ||
            attachment.attachmentPath.endsWith('.xlsx')
        );

        const documentPaths = documentsToDownload.map(document => document.attachmentPath);
        const hasImage = rowData.attachmentsModel.some(attachment => attachment.attachmentPath.endsWith('.png') || attachment.attachmentPath.endsWith('.jpg') || attachment.attachmentPath.endsWith('.jpeg'));
        const hasVideo = rowData.attachmentsModel.some(attachment => attachment.attachmentPath.endsWith('.mp4') || attachment.attachmentPath.endsWith('.mp5') || attachment.attachmentPath.endsWith('.webm') || attachment.attachmentPath.endsWith('.mov') || attachment.attachmentPath.endsWith('.3gp') || attachment.attachmentPath.endsWith('.3g2') || attachment.attachmentPath.endsWith('.mpeg'));

        return (
            <div>
                {documentsToDownload.length > 0 && (
                    <Button
                        icon="pi pi-download"
                        className="custom-btn-edit"
                        onClick={() => downloadDocuments(documentPaths)}
                    />
                )}
                {hasImage && (
                    <Button
                        icon="pi pi-eye"
                        className="custom-btn-edit"
                        onClick={() => {
                            setSelectedAttachment(rowData.attachmentsModel.filter(attachment =>
                                attachment.attachmentPath.endsWith('.png') ||
                                attachment.attachmentPath.endsWith('.jpg') ||
                                attachment.attachmentPath.endsWith('.jpeg')
                            ));
                            setViewAttachmentDialog(true);
                        }}
                    />
                )}
                {hasVideo && (
                    <Button
                        icon="pi pi-video"
                        className="custom-btn-edit"
                        onClick={() => {
                            setSelectedVideoAttachment(rowData.attachmentsModel.filter(attachment =>
                                attachment.attachmentPath.endsWith('.mp4') ||
                                attachment.attachmentPath.endsWith('.mp5') ||
                                attachment.attachmentPath.endsWith('.webm') ||
                                attachment.attachmentPath.endsWith('.mov') ||
                                attachment.attachmentPath.endsWith('.3gp') ||
                                attachment.attachmentPath.endsWith('.3g2') ||
                                attachment.attachmentPath.endsWith('.mpeg')
                            ));
                            setVideoAttachmentDialog(true);
                        }}
                    />
                )}
                {(documentsToDownload.length === 0 && !hasImage && !hasVideo) && (
                <Button
                    // icon="pi pi-question"
                    label='-'
                    className="custom-btn-edit"
                    tooltip="No attachment available"
                />
            )}
            </div>
        );
    };

    const downloadDocuments = async (documentPaths) => {
        try {
            for (let i = 0; i < documentPaths.length; i++) {
                const documentPath = documentPaths[i];
                const response = await fetch(`${appUrl.baseUrl}${documentPath}`);
                const blob = await response.blob();
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', '');
                link.setAttribute('target', '_blank');
                link.click();
                URL.revokeObjectURL(url);
                await new Promise(resolve => setTimeout(resolve, 1000)); // Delay each download by 1 second
            }
        } catch (error) {
            console.error("Error downloading documents:", error);
            toast.error("Failed to download documents");
        }
    };

    const onHideViewAttachment = () => {
        setViewAttachmentDialog(false);
    };

    const onHide = () => {
        setDialog(false);
    };

    useEffect(() => {
        GetAppJourney();
    }, [res]);

    useEffect(() => {
        if (searchQuery) {
            setFilteredData(
                appJourneyList?.filter(item =>
                    item.productName?.toLowerCase().includes(searchQuery?.toLowerCase())||
                    item.channelName?.toLowerCase().includes(searchQuery?.toLowerCase())||
                    item.title?.toLowerCase().includes(searchQuery?.toLowerCase())
                )
            );
        } else {
            setFilteredData(appJourneyList);
        }
    }, [searchQuery, appJourneyList]);
    const isAdmin = localStorage.getItem('admin')
    return (
        <> 
        <ConfirmDialog />
        <ToastContainer />
            <Dialog visible={viewAttachmentDialog} onHide={onHideViewAttachment} header="View Attachment" style={{ width: '50vw' }}>
                {selectedAttachment && <ViewAttachment attachment={selectedAttachment} />}
            </Dialog>
            <Dialog visible={videoAttachmentDialog} onHide={() => setVideoAttachmentDialog(false)} header="View Video Attachment" style={{ width: '50vw' }}>
                {selectedVideoAttachment && <CustomVideo videoAttachments={selectedVideoAttachment} />}
            </Dialog>
            <Dialog visible={dialog} onHide={onHide} header={editable ? "Edit App Journey" : "Add App Journey"} style={{ width: "60vw" }}>
                <AddEditAdminApp dialog={dialog} setRes={setRes} setDialog={setDialog} editable={editable} rowData={rowselect} onHide={onHide} />
            </Dialog>

            <div className='grid'>
                <div className="xl:col-10 md:col-8 sm:col-8 col-4">
                    <h5 style={{ fontWeight: "bold" }}>App Journey</h5>
                </div>
               
                {isAdmin === 'true' || isAdmin === true ? (
                <div className="xl:col-2 md:col-4 sm:col-4 col-8">
                    <div>
                        <Button className="custom-btn w80" label="+ &nbsp; Add New" onClick={() => { setEditable(false); setDialog(true); }} />
                    </div>
                </div>

            ) : null}

                <ToastContainer />
                <div className='md:col-12 overflowX'>
                {loading && <Loader />}
                    <div className="table-custom">
                        <DataTable
                            value={filteredData}
                            scrollable
                            scrollHeight="600px"
                            rows={9}
                            className='overflowX'
                            paginator
                        >
                            <Column header="Product" field='productName' className='left-border' />
                            <Column header="Channel" className='pointer' body={(rowData) => (
                                <span title={rowData.channelName}>
                                    {rowData.channelName?.length > 1 ? `${rowData.channelName.slice(0, 1)}...` : rowData.channelName}
                                </span>
                            )} />
                            <Column field="title" header="Title" />
                            <Column header="Created Date/Time" field='productCreatedDate'
                                body={(rowData) => new Date(rowData.createdDate).toLocaleString('en-GB', {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    hour12: true
                                })}
                            />
                             <Column
                                field="updatedDate"
                                header="Updated Date/Time"
                                body={(rowData) => {
                                    if (!rowData.updatedDate) {
                                        return '-';
                                    }
                                    return new Date(rowData.updatedDate).toLocaleString('en-GB', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        hour12: true,
                                    });
                                }}
                            />
                              <Column header="Attachment" body={actionDocument} style={{ textAlign: 'center', minWidth: '12rem' }} />
                              {isAdmin === 'true' || isAdmin === true ? (
                                <Column header="Action" body={actionTemplate} style={{ minWidth: '10rem' }} />
                                ) : null}
                            
                          
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AdminZAppJourney;
